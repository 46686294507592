import { EventEmitter } from "events";
// import SessionStore from "./SessionStore";

// import { checkStatus, errorHandler, errorHandlerLogin } from "./helpers";

export const VIEW_USER = 0;
export const VIEW_ORGADMIN = 1;
export const VIEW_SYSADMIN = 2;

class LocalStore extends EventEmitter {
  constructor() {
    super();
    this.client = null;
    this.user = null;
    this.organizations = [];
    this.settings = {};
    this.branding = {};
  }

  getApplicationID() {
    const appID = localStorage.getItem("applicationID");
    if (appID === "") {
      return null;
    }

    return appID;
  }

  setApplicationID(id) {
    localStorage.setItem("applicationID", id);
    this.emit("application.change");
  }

  getView() {
    const view = localStorage.getItem("view");
    if (view === "") {
      return 0;
    }
    return Number(view);
  }

  hasViewSystemAdmin() {
    return (this.getView() === VIEW_SYSADMIN);
  }

  hasViewOrgAdmin() {
    return (this.getView() >= VIEW_ORGADMIN);
  }

  hasViewUser() {
    return (this.getView() >= VIEW_USER);
  }

  setView(view) {
    // Check SessionStore.isAdmin and isOrganizationAdmin before calling this function
    // SessionStore needs to import this unit
    if (
      (view > VIEW_SYSADMIN) ||
      (view < VIEW_USER)
      // (view === VIEW_SYSADMIN && !SessionStore.isAdmin()) ||
      // (view === VIEW_ORGADMIN && !(SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(SessionStore.getOrganizationID())))
    ) {
      view = VIEW_USER;
    }
    localStorage.setItem("view", view);
    this.emit("view.change");
  }

}

const localStore = new LocalStore();
export default localStore;
