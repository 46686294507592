import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import {PropTypes} from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";

import Button from '@material-ui/core/Button';

// import TitleBar from "../../components/TitleBar";
// import TitleBarTitle from "../../components/TitleBarTitle";
// import TitleBarButton from "../../components/TitleBarButton";
import OrganizationStore from "../../stores/OrganizationStore";
import AircomSystemStore from "../../stores/AircomSystemStore";
import UpdateOrganization from "./UpdateOrganization";
import SessionStore from "../../stores/SessionStore";

import theme from "../../theme";


const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class OrganizationLayout extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
    };
  }

  componentDidMount() {
    this.loadData();
    // this.locationToTab();
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.loadData();
    // this.locationToTab();
  }

  setIsAdmin = () => {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  loadData = () => {
    OrganizationStore.get(this.props.organizationID, resp => {
      this.setState({
        organization: resp,
      });
    });
  }

  deleteOrganization = () => {
    if (window.confirm("Are you sure you want to delete this organization?")) {
      // Topnav component will detect deletion and deal with it
      AircomSystemStore.deleteOrganization(this.props.organizationID, () => {});
    }
  }

  render() {
    if (this.state.organization === undefined) {
      return <div></div>;
    }

    return(
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <Route exact path={`${this.props.match.path}`} render={props => <UpdateOrganization organization={this.state.organization.organization} admin={this.state.admin} {...props} />} />
        </Grid>

        {this.props.admin && (
          <Grid item xs={12} className={this.props.classes.buttons}>
            <Button variant="outlined" className={this.props.classes.button} color="secondary" onClick={this.deleteOrganization} >
              <Delete className={this.props.classes.icon} />
                Delete Organization
              </Button>
          </Grid>
        )}

      </Grid>
    );
  }
}

OrganizationLayout.propTypes = {
  match: PropTypes.any.isRequired,
  organizationID: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  admin: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(OrganizationLayout));
