import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Grid from '@material-ui/core/Grid';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import SessionStore from "../../stores/SessionStore";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListOrganizations from "../organizations/ListOrganizations";
import theme from "../../theme";
import SettingsIcon from "@material-ui/icons/Settings";
import ListUsers from "../users/ListUsers";
import AccessDenied from "../../components/AccessDenied";
import UpdateSystemSettings from "./UpdateSystemSettings";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class SystemDashboard extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.locationToTab = this.locationToTab.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin(),
    });
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.endsWith("/users")) {
      tab = 1;
    }

    if (window.location.href.endsWith("/settings")) {
      tab = 2;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (!SessionStore.isAdmin()) {
      return <AccessDenied />;
    }
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={[
            <TitleBarButton
              key={1}
              icon={<SettingsIcon />}
              label="System Setup"
              to={"/network-servers"}
            />,
          ]}
        >
          <TitleBarTitle title="System" />
        </TitleBar>
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab value={0} label="Organizations" component={Link} to={"/system"} />
            <Tab value={1} label="Users" component={Link} to={`/system/users`} />
            <Tab value={2} label="Settings" component={Link} to={`/system/settings`} />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Route exact path={"/system/users"} render={props => <ListUsers admin={true} {...props} />} />
          <Route exact path={"/system/settings"} render={props => <UpdateSystemSettings admin={true} {...props} />} />
          <Route exact path={"/system"} render={props => <ListOrganizations admin={true} {...props} />} />
        </Grid>
      </Grid>
    );
  }
}

SystemDashboard.propTypes = {
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(SystemDashboard));
