import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import ApplicationStore from "../../stores/ApplicationStore";
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";
import SessionStore from "../../stores/SessionStore";
import AccessDenied from "../../components/AccessDenied";
import AircomOutput from "./AircomOutput"
import ApplicationDownlinksDetails from "./ApplicationDownlinksDetails";
import PvControl from "./PvControl";
import { Typography } from "@material-ui/core";

const CONTROL_TYPE_STEP = 1;
const CONTROL_TYPE_PV = 2;

const styles = {
};

class ApplicationDownlinks extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
      aircomOutput: false,
    };

    this.onChangeTab = this.onChangeTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();    
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    AppDeviceGroupStore.list(this.props.match.params.applicationID, 999, 0, resp => {
      let showStepControl = false;
      let showPVControl = false;
      let showTabs = false;

      resp.result.every((group) => {
        if (group.outputControlType == CONTROL_TYPE_STEP) {
          showStepControl = true;
          showTabs = true;
        } else if (group.outputControlType == CONTROL_TYPE_PV) {
          showPVControl = true;
          showTabs = true;
        }
        return !(showStepControl && showPVControl); // Only stop if both are true
      });

      this.setState({
        showStepControl,
        showPVControl,
        showTabs
      });
    });

    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);    
  }
  
  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.endsWith("/pv-control")) {
      tab = 1;
    } else if (window.location.href.endsWith("/step-control")) {
      tab = 2;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.admin !== true) {
      return <AccessDenied />;
    }    
    if (this.state.application === undefined) {
      return(<div></div>);
    }
    const {showTabs, showPVControl, showStepControl} = this.state;

    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title={`Application Downlinks (${this.state.application.application.name})`} />
        </TitleBar>
      {!showTabs && (
        <Grid item xs={12}>
          <Typography>
            No device types are currently configured to use downlinks.
          </Typography>          
        </Grid>
      )}
      {showTabs && (
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab label="Pending Downlinks" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/downlinks`} />
            {showPVControl &&
              <Tab label="PV Control" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/pv-control`} />
            }
            {showStepControl &&
              <Tab label="Step Control" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/step-control`} />
            }
          </Tabs>
        </Grid>
      )}
      {showTabs && (
        <Grid item xs={12}>
          <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/downlinks`} render={props => <ApplicationDownlinksDetails application={this.state.application.application} aircomOutput={this.state.aircomOutput} {...props} />} />
          <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/pv-control`} render={props => <PvControl application={this.state.application.application} {...props} />} />
          <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/step-control`} render={props => <AircomOutput application={this.state.application.application} {...props} />} />
        </Grid>
      )}
    </Grid>
    );
  }
}

ApplicationDownlinks.propTypes = {
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(ApplicationDownlinks));
