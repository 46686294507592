import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class AircomSystemStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/aircomSystem.swagger.json", sessionStore.getClientOpts());
  }

  getSystemSettings(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AircomSystemService.AircomSystemService_GetSettings({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateSystemSettings(systemSettings, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AircomSystemService.AircomSystemService_UpdateSettings({
        "systemSettings.id": systemSettings.id,
        body: {
          systemSettings: systemSettings,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteApplication(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AircomSystemService.AircomSystemService_DeleteApplication({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.emit("application.deleted", id);
          this.notifyApp("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteOrganization(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AircomSystemService.AircomSystemService_DeleteOrganization({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.emit("organization.deleted", id);
          this.notifyOrg("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "system settings have been " + action,
      },
    });
  }

  notifyApp(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "application has been " + action,
      },
    });
  }

  notifyOrg(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "organization has been " + action,
      },
    });
  }
}

const aircomSystemStore = new AircomSystemStore();
export default aircomSystemStore;
