import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableCellLink from "../../components/TableCellLink";
import Button from '@material-ui/core/Button';


// import Check from "mdi-material-ui/Check";
// import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";

// import TitleBar from "../../components/TitleBar";
// import TitleBarTitle from "../../components/TitleBarTitle";
// import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import OrganizationStore from "../../stores/OrganizationStore";
import theme from "../../theme";

const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
};

class ListOrganizations extends Component {
  getPage(limit, offset, callbackFunc) {
    OrganizationStore.list("", limit, offset, callbackFunc);
  }

  getRow(obj) {
    // let icon = null;
    // if (obj.canHaveGateways) {
    //   icon = <Check />;
    // } else {
    //   icon = <Close />;
    // }

    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/organizations/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.displayName}</TableCell>
        {/* <TableCell>{icon}</TableCell> */}
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        {/* <TitleBar
          buttons={[
            <TitleBarButton
              key={1}
              label="Create"
              icon={<Plus />}
              to={`/organizations/create`}
            />,
          ]}
        >
          <TitleBarTitle title="System - Organizations" />
        </TitleBar> */}
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Display name</TableCell>
                {/* <TableCell>Can have gateways</TableCell> */}
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>

        {this.props.admin && (
          <Grid item xs={12} className={this.props.classes.buttons}>
            <Button variant="outlined" className={this.props.classes.button} component={Link} to={"/organizations/create"}>
              <Plus className={this.props.classes.icon} />
                Create Organization
              </Button>
          </Grid>
        )}

      </Grid>
    );
  }
}

export default withStyles(styles)(ListOrganizations);
