import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";

class AppItemStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/appItem.swagger.json", sessionStore.getClientOpts());
  }

  list(applicationId, limit, offset, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.AppItemService.AppItemService_List({
        applicationId: applicationId,
        limit: limit,
        offset: offset,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_Get({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  create(appItem, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_Create({
        body: {
          appItem: appItem,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  update(appItem, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_Update({
        "appItem.id": appItem.id,
        body: {
          appItem: appItem,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_Delete({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "app item has been " + action,
      },
    });
  }


  listPayloadItem(callbackFunc) {
    this.swagger.then((client) => {
      client.apis.AppItemService.AppItemService_PayloadItemList({
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getPayloadItem(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_GetUpPayloadItem({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getGroupItemMap(appDeviceGroupId, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_GroupItemMap({
        appDeviceGroupId: appDeviceGroupId,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateGroupItemMap(appDeviceGroupId, itemsMap, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppItemService.AppItemService_UpdateGroupItemMap({
        appDeviceGroupId: appDeviceGroupId,
        body: {
          items: itemsMap,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notifyGroupItems("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notifyGroupItems(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "device type items have been " + action,
      },
    });
  }
}

const appItemStore = new AppItemStore();
export default appItemStore;
