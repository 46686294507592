import React, { Component } from "react";
// import { Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import ApplicationStore from "../../stores/ApplicationStore";
import SessionStore from "../../stores/SessionStore";
import ListDevices from "../devices/ListDevices";

// import theme from "../../theme";

const styles = {
  // tabs: {
  //   borderBottom: "1px solid " + theme.palette.divider,
  //   height: "48px",
  //   overflow: "visible",
  // },
};


class ApplicationDevices extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    SessionStore.on("change", this.setIsAdmin);

    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  deleteApplication() {
    if (window.confirm("Are you sure you want to delete this application? This will also delete all devices part of this application.")) {
      ApplicationStore.delete(this.props.match.params.applicationID, () => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications`);
      });
    }
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.match(/.*\/integrations.*/g)) {
      tab = 2;
    } else if (window.location.href.endsWith("/edit")) {
      tab = 1;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.application === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title={`Application Devices (${this.state.application.application.name})`} />
        </TitleBar>

        <Grid item xs={12}>
          <ListDevices application={this.state.application.application} {...this.props} />
        </Grid>
      </Grid>
    );
  }
}

ApplicationDevices.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
}

export default withStyles(styles)(ApplicationDevices);
