import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
// import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import HexTextField from '../../components/HexTextField';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import {decodePayload, encodePayload, MAX_PACKET_BYTES} from "../../helpers/packetHelper";
import AppItemStore from "../../stores/AppItemStore";
// import { formatReading } from "../../styles/formatters";
import Checkbox from '@material-ui/core/Checkbox';
// import { styles } from '../../styles/aircom-styles';

const styles = {
};

function formatHex(input) {
  // Insert space every two characters
  if (!input) {
    return '';
  }
  let result = '';
  for (let i = 0; i < input.length; i++) {
    if (i > 0 && (i % 2 === 0)) {
      result = result + ' ' + input[i];
    } else {
      result = result + input[i];
    }
  }
  return result.toUpperCase();
}

function isHexValid(str) {
  const legend = '0123456789abcdef';
  for(let i = 0; i < str.length; i++){
    if(legend.includes(str[i])){
      // eslint-disable-next-line no-continue
      continue;
    };
    return false;
  };
  return true;
};

class PacketEncoderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payloadItems: null,
      payloadFields: {
        d1: null,
        d2: null,
        d3: null,
        d4: null,
        a1: null,
        a2: null,
        a3: null,
        a4: null,
        a5: null,
        c1: null,
        c2: null,
        bv: null,
        temp: null,
        time: null,
        modbusPoll1: null,
        modbusPoll2: null,
        modbusPoll3: null,
        modbusPoll4: null,
        modbusPoll5: null,
        modbusPoll6: null,
        modbusPoll7: null,
        modbusPoll8: null,
        modbusPoll9: null,
        modbusPoll10: null,
      },
      packetPayload: "",
      decodedPacket: null,
    };

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onPollCheckboxChange = this.onPollCheckboxChange.bind(this);
    this.onPayloadFieldsChange = this.onPayloadFieldsChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onPollTextChange = this.onPollTextChange.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  componentDidMount() {
    // Load packet items
    AppItemStore.listPayloadItem(resp => {
      this.setState({
        payloadItems: resp.result.filter(item => item.abbreviation < 'MB1' || item.abbreviation > 'MB9').sort((a, b) => a.abbreviation > b.abbreviation ? 1 : -1),
      });
    });
  }

  onPayloadFieldsChange(payloadFields) {
    // payloadFields contain text - need to convert to numbers
    // Don't change payloadFields
    // Create pf to be used to encode packet

    // Clone payload fields (so not amending original)
    let pf = {...payloadFields};

    // Digitals
    for (let i = 1; i <= 4; i++) {
      if (pf[`d${i}`] !== null) {
        pf[`d${i}`] = pf[`d${i}`] === '0' ? 0 : 1;
      }
    }

    // Analogues
    for (let i = 1; i <= 5; i++) {
      if (pf[`a${i}`] !== null) {
        pf[`a${i}`] = Number(pf[`a${i}`]);
      }
    }

    // Counters
    for (let i = 1; i <= 2; i++) {
      if (pf[`c${i}`] !== null) {
        pf[`c${i}`] = Number(pf[`c${i}`]);
      }
    }

    if (pf.bv !== null) {
      pf.bv = Number(pf.bv);
    }

    if (pf.temp !== null) {
      pf.temp = Number(pf.temp);
    }

    if (pf.time !== null) {
      pf.time = Number(pf.time);
    }

    // Polls
    for (let i = 1; i <= 10; i++) {
      let pollHexString = pf[`modbusPoll${i}`];
      if (pollHexString !== null) {
        if ((pollHexString.length % 2) === 1) {
          pollHexString = '0' + pollHexString;
        }
        if (!isHexValid(pollHexString)) {
          pollHexString = '';
        }
        pf[`modbusPoll${i}`] = pollHexString;
      }
    }

    const packetPayload = encodePayload(pf);
    const decodedPacket = decodePayload(packetPayload);
    this.setState({payloadFields, packetPayload, decodedPacket});
  }

  onCheckboxChange(e) {
    let {payloadFields} = this.state;
    payloadFields[e.target.id] = e.target.checked ? '0' : null;
    this.onPayloadFieldsChange(payloadFields);
  }

  onPollCheckboxChange(e) {
    let {payloadFields} = this.state;
    payloadFields[e.target.id] = e.target.checked ? '00000000' : null;
    this.onPayloadFieldsChange(payloadFields);
  }

  onTextChange(e) {
    let {payloadFields} = this.state;
    payloadFields[e.target.id] = e.target.value;
    this.onPayloadFieldsChange(payloadFields);
  }

  onPollTextChange(e) {
    let {payloadFields} = this.state;
    payloadFields[e.target.id] = e.target.value;
    if (isHexValid(payloadFields[e.target.id])) {
      this.onPayloadFieldsChange(payloadFields);
    }
  }

  clearAll() {
    this.setState({
      payloadFields: {
        d1: null,
        d2: null,
        d3: null,
        d4: null,
        a1: null,
        a2: null,
        a3: null,
        a4: null,
        a5: null,
        c1: null,
        c2: null,
        bv: null,
        temp: null,
        time: null,
        modbusPoll1: null,
        modbusPoll2: null,
        modbusPoll3: null,
        modbusPoll4: null,
        modbusPoll5: null,
        modbusPoll6: null,
        modbusPoll7: null,
        modbusPoll8: null,
        modbusPoll9: null,
        modbusPoll10: null,
      },
      packetPayload: "",
      decodedPacket: null,
    });
  }

  render() {
    const {packetPayload, payloadItems, payloadFields, decodedPacket} = this.state;
    const {classes} = this.props;

    if (payloadItems === null) {
      return null;
    }

    const spaceLeft = MAX_PACKET_BYTES - Math.floor(packetPayload.length * 0.5);

    const formattedPayload = formatHex(packetPayload);

    let polledRows = [];
    for (let i = 1; i < 11; i++) {
      const itemChecked = payloadFields[`modbusPoll${i}`] !== null;
      const thisValue = payloadFields[`modbusPoll${i}`];
      let decodedValue = decodedPacket ? decodedPacket[`modbusPoll${i}`] : null;
      decodedValue = (decodedValue === 1) ? 'Y' : '-';
      polledRows.push(
        <TableRow>
          <TableCell>
            <Typography>
              <Checkbox 
                id={`modbusPoll${i}`}
                checked={itemChecked}
                onChange={this.onPollCheckboxChange}
                color="primary"
                disabled={spaceLeft < 1 && !itemChecked}
              />
              {`POLL-${i}`}</Typography>
          </TableCell>
          <TableCell>
            <HexTextField
              id={`modbusPoll${i}`}
              value={thisValue}
              helperText="Enter the poll data (hex format)"
              margin="normal"
              onChange={this.onPollTextChange}
              disabled={!itemChecked}
              // required
              fullWidth
            />
          </TableCell>
          <TableCell>{decodedValue}</TableCell>
      </TableRow>
      )
    }

    return(
      <Paper className={classes.paper}>
        <TextField
          id="name"
          label="Packet payload: "
          // helperText="Enter the packet payload (hex format)"
          margin="normal"
          value={formattedPayload}
          // onChange={this.onChangePacketPayload}
          fullWidth
          // required
        />
        <Typography>
          Packet length (bytes):&nbsp;
          {packetPayload.length / 2}
          <Button
            style={{marginLeft:16}}
            variant="contained"
            endIcon={<ClearAllIcon />}
            onClick={this.clearAll}
          >
          Clear All
        </Button>
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>{'Desired Value'}</TableCell>
              <TableCell>{'Encoded Value'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payloadItems.map((item) => {
              const thisValue = payloadFields[item.abbreviation.toLocaleLowerCase()];
              const itemChecked = thisValue !== null;
              let decodedValue = decodedPacket ? decodedPacket[item.abbreviation.toLocaleLowerCase()] : null;
              if (decodedValue === null || decodedValue === undefined) {
                decodedValue = '-';
              }
              return(
                <TableRow>
                  <TableCell>
                  <Typography>
                  <Checkbox 
                    id={item.abbreviation.toLocaleLowerCase()}
                    checked={itemChecked}
                    onChange={this.onCheckboxChange}
                    color="primary"
                    disabled={spaceLeft < 6 && !itemChecked} // To be safe
                  />
                  {item.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      id={item.abbreviation.toLocaleLowerCase()}
                      // label={this.props.label}
                      value={thisValue}
                      // helperText={this.props.helperText}
                      margin="normal"
                      onChange={this.onTextChange}
                      disabled={!itemChecked}
                      // required
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>{decodedValue}</TableCell>
                </TableRow>
              )
            })}
            {polledRows}
          </TableBody>
        </Table>
      </Paper>      
    );
  }
}

PacketEncoderForm.propTypes = {
  classes: PropTypes.any.isRequired,
  // match: PropTypes.any.isRequired,  
}

export default withStyles(styles)(withRouter(PacketEncoderForm));
