import React, { Component } from "react";
import PropTypes from "prop-types";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import Datetime from 'react-datetime';

import moment from "moment";

import RefreshIcon from '@material-ui/icons/Refresh';

import DashCard from "../../components/DashCard";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from "@material-ui/core/styles";
import { styles } from '../../styles/aircom-styles';

import DeviceStore from "../../stores/DeviceStore";
import AppItemStore from "../../stores/AppItemStore";

// import SessionStore from "../../stores/SessionStore";

export class Dashboard extends Component {
  constructor() {
    super();

    const timeLoText = moment().subtract(1, 'day').startOf('day').format();
    const timeHiText = moment().add(1, 'day').startOf('day').format();

    this.state = {
      loadingAppData: false,
      applicationID: null,
      // dashCards: null,
      deviceFilter: null,
      devices: null,
      appItems: null,
      spotlitItems: null,
      dialogOpen: false,
      timeFilter: 60,
      autoRefresh: true,
      refreshSeconds: 60,
      timeLoText,
      timeHiText,
      timeLoInvalid: false,
      timeHiInvalid: false,
      cacheCounter: 0,
    };

    this.handleChangeDevice = this.handleChangeDevice.bind(this);
    this.handleChangeTimeFilter = this.handleChangeTimeFilter.bind(this);
    this.handleChangeRefresh = this.handleChangeRefresh.bind(this);
    this.timer = this.timer.bind(this);
    this.handleChangeTimeLo = this.handleChangeTimeLo.bind(this);
    this.handleChangeTimeHi = this.handleChangeTimeHi.bind(this);
    this.loadAppSpecificData = this.loadAppSpecificData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.addSpotlitItem = this.addSpotlitItem.bind(this);
    this.clearSpotlitItems = this.clearSpotlitItems.bind(this);
  }

  componentDidMount() {
    this.loadAppSpecificData(); // Includes fetchData

    const intervalID = setInterval(this.timer, (this.state.refreshSeconds * 1000));
    // store intervalID in the state so it can be cleared later:
    this.setState({intervalID});
  }

  componentWillUnmount() {
    // use intervalID from the state to clear the interval
    clearInterval(this.state.intervalID);
  }

  componentDidUpdate(prevProps) {
    if ((this.props === prevProps) || this.state.loadingAppData) {
      return;
    }
    // New route either from link or user typed new url into browser address bar
    this.loadAppSpecificData();
  }

  loadAppSpecificData() {
    this.setState({
      loadingAppData: true,
      appItems: null,
      spotlitItems: null,
      applicationID: null,
      // dashCards: null,
      devices: null,
      deviceFilter: null,
    }, () => {

      const applicationID = Number(this.props.match.params.applicationID);

      // Get app items and generate cards
      AppItemStore.list(applicationID, 100, 0, resp => {
        
        // Set appItems, i.e. those to show on dashboard
        const appItems = resp.result.filter(appItem => (appItem.showOnDashboard && (appItem.numberFormat.localeCompare("Time") !==0)));
        // .map(appItem => parseInt(appItem.id, 10));
  
        // Get devices
        DeviceStore.list({applicationID, limit: 9999, offset: 0}, resp => {
          const devices = resp.result.map(item => {
            return {name: item.name, devEUI: item.devEUI};
          });
          this.setState({
            appItems,
            applicationID,
            devices,
            deviceFilter: null,
            loadingAppData: false,
          }, this.fetchData);
        });
      });
    })
  }
 
  timer() {
    if (this.state.autoRefresh)
    {
      this.fetchData();
    }
  }

  fetchData() {
    const { deviceFilter, timeFilter, timeLoText, timeHiText, cacheCounter } = this.state;
    this.refreshStateItems({ deviceFilter, timeFilter, timeLoText, timeHiText, cacheCounter: cacheCounter + 1});
  }

  handleChangeTimeFilter(event) {
    const timeFilter = event.target.value;
    const { deviceFilter, timeLoText, timeHiText } = this.state;
    this.refreshStateItems({ deviceFilter, timeFilter, timeLoText, timeHiText });
  }

  /**
   * 
   * @param {timeLoText} timeLoText 
   * 
   * timeLoText is either (a) valid moment or else (b) text from control
   */
  handleChangeTimeLo(newTimeLo) {
    if (typeof newTimeLo === 'string' || newTimeLo instanceof String) {
      this.setState({ timeLoInvalid: true });
      return;
    }

    let timeLo = moment(newTimeLo);

    // We know timeLo is valid moment
    const timeLoInvalid = false;
    let { timeHiText } = this.state;
    if (timeLo.diff(moment(timeHiText)) > 0) {
      timeHiText = timeLo.add(1, 'day').format();
    }
    this.setState({ timeLoInvalid });
    const { deviceFilter, timeFilter } = this.state;
    this.refreshStateItems({ deviceFilter, timeFilter, timeLoText: timeLo.format(), timeHiText });
  }

  handleChangeTimeHi(newTimeHi) {
    if (typeof newTimeHi === 'string' || newTimeHi instanceof String) {
      this.setState({ timeHiInvalid: true });
      return;
    }

    let timeHi = moment(newTimeHi);

    const timeHiInvalid = false;
    let { timeLoText } = this.state;
    if (moment(timeLoText).diff(timeHi) > 0) {
      timeLoText = timeHi.subtract(1, 'day');
    }
    this.setState({ timeHiInvalid });
    const { deviceFilter, timeFilter } = this.state;
    this.refreshStateItems({ deviceFilter, timeFilter, timeLoText, timeHiText: timeHi.format() });
  }

  handleChangeRefresh(event) {
    let {intervalID} = this.state;
    const refreshSeconds = event.target.value;
    clearInterval(intervalID);
    intervalID = setInterval(this.timer, (refreshSeconds * 1000));
    // store intervalID in the state so it can be accessed later:
    this.setState({
      refreshSeconds,
      intervalID
    });
  }

  /**
   * refreshStateItems
   * Ensures that all state items required for data set at same time with refreshed timeHiText and timeLoText
   * @param {*} params 
   */
  refreshStateItems(params = {}) {
    const { deviceFilter = null, timeFilter = 0 } = params;
    let { timeLoText = moment(0).format(), timeHiText = moment().format() } = params;

    if (timeFilter > 0) {
      timeHiText = moment().format();
      timeLoText = moment(timeHiText).subtract(timeFilter, 'minutes').format();
    } else if (moment(timeHiText).diff(moment()) > 0) {
      timeHiText = moment().format();
    }

    // Set state items and force re-render
    let {cacheCounter, spotlitItems} = this.state;
    if (!deviceFilter) {
      spotlitItems = null;
    }
    this.setState({
      deviceFilter,
      spotlitItems,
      timeFilter,
      timeLoText,
      timeHiText,
      cacheCounter: cacheCounter + 1
    });

  }

  handleChangeDevice(event) {
    const deviceFilter = event.target.value ? event.target.value : null;
    const { timeFilter, timeLoText, timeHiText } = this.state;
    this.refreshStateItems({ deviceFilter, timeFilter, timeLoText, timeHiText });
  }

  handleCheck = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });
  }

  addSpotlitItem(itemID) {
    let {appItems, spotlitItems} = this.state;
    if (spotlitItems === null) {
      this.setState({spotlitItems: appItems.filter(appItem => appItem.id === itemID)});
    } else if (spotlitItems.length === 1) {
      Array.prototype.push.apply(spotlitItems, appItems.filter(appItem => appItem.id === itemID));
      this.setState({spotlitItems});
    }
  }

  clearSpotlitItems() {
    this.setState({spotlitItems: null});
  }

  render() {
    const {
      autoRefresh,
      // dashCards,
      cacheCounter,
      deviceFilter,
      timeFilter,
      devices,
      timeLoText,
      timeLoInvalid,
      timeHiText,
      timeHiInvalid,
      applicationID,
      appItems,
      spotlitItems,
    } = this.state;
    const dataLoaded = (devices !== null) && (appItems !== null);
    const { classes, match } = this.props;
    const showTimeInputs = !(timeFilter > 0);
    const filterName = deviceFilter ? deviceFilter.name : "";
    const devEUI = deviceFilter ? deviceFilter.devEUI : "";
    const organizationID = match.params.organizationID;

    let timeLoClass = classes.rdtInput;
    if (timeLoInvalid) {
      timeLoClass = classes.rdtInputError;
    }

    let timeHiClass = classes.rdtInput;
    if (timeHiInvalid) {
      timeHiClass = classes.rdtInputError;
    }

    let gridItems = [];
    let gridKey = 1;
    if (!dataLoaded) {
      gridItems =
      [
      <Grid item xs={12} key="1">
        <Typography>Loading data...</Typography>
      </Grid>,
      ]

    } else if (spotlitItems !== null && spotlitItems.length > 0) {
      // Populate spolight graph(s)
      const itemProps = {
        label:spotlitItems[0].name,
        appItemId: spotlitItems[0].id,
        isSpotlit: true,
        addSpotlight: this.addSpotlight,
        clearSpotlight: this.clearSpotlitItems,
      };
      gridItems.push(
        <Grid item xs={12} key={gridKey++}>
          <DashCard 
            appItemIds={[spotlitItems[0].id]}
            applicationID={applicationID}
            cacheCounter={cacheCounter}
            cardType={4}
            deviceFilter={devEUI}
            filterName={filterName}
            itemProps={itemProps}
            organizationID={Number(organizationID)}
            timeLo={timeLoText}
            timeHi={timeHiText}
          />
        </Grid>
      );

    } else {
      const appItemIds = appItems.map(appItem => parseInt(appItem.id, 10));
      if (deviceFilter !== null) {
        // Latest measurements
        gridItems.push(
          <Grid item xs={12} key={gridKey++}>
            <DashCard 
              cardType={1}
              applicationID={applicationID}
              organizationID={organizationID}
              deviceFilter={devEUI}
              filterName={filterName}
              timeLo={timeLoText}
              timeHi={timeHiText}
              appItemIds={appItemIds}
              cacheCounter={cacheCounter}
            />
          </Grid>
        )

        const graphCards = appItems.map(appItem => {
          const itemProps = {
            label:appItem.name,
            appItemId: appItem.id,
            isSpotlit: false,
            addSpotlight: this.addSpotlitItem
          };
          return (
            <Grid item xs={6} key={gridKey++}>
              <DashCard 
                cardType={4}
                applicationID={applicationID}
                organizationID={Number(organizationID)}
                deviceFilter={devEUI}
                filterName={filterName}
                timeLo={timeLoText}
                timeHi={timeHiText}
                appItemIds={appItemIds}
                cacheCounter={cacheCounter}
                itemProps={itemProps}
              />
            </Grid>
          )
        });
        Array.prototype.push.apply(gridItems, graphCards);
      }


      // Latest readings
      gridItems.push(
          <Grid item xs={12} key={gridKey++}>
            <DashCard 
              cardType={2}
              applicationID={applicationID}
              organizationID={organizationID}
              deviceFilter={devEUI}
              filterName={filterName}
              timeLo={timeLoText}
              timeHi={timeHiText}
              appItemIds={appItemIds}
              cacheCounter={cacheCounter}
            />
          </Grid>
      )

      // Transmissions log
      gridItems.push(
        <Grid item xs={12} key={gridKey++}>
          <DashCard 
            cardType={3}
            applicationID={applicationID}
            organizationID={organizationID}
            deviceFilter={devEUI}
            filterName={filterName}
            timeLo={timeLoText}
            timeHi={timeHiText}
            appItemIds={appItems.map(appItem => parseInt(appItem.id, 10))}
            cacheCounter={cacheCounter}
            // addSpotlight={this.addSpotlitItem}
            // props={}
          />
        </Grid>
      )
    }

    return (
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="Dashboard" />
        </TitleBar>
        <Grid item xs={12}>
          {dataLoaded && (
            <Paper className={classes.paper}>
              <FormControlLabel
                control={
                  <Select
                    className={classes.selectField}
                    value={deviceFilter ? deviceFilter : ""}
                    onChange={this.handleChangeDevice}
                    inputProps={{
                      name: 'device-name',
                      id: 'device-name',
                    }}
                  >
                    <MenuItem key="" value=""><em>All</em></MenuItem>
                      {devices.map(device => (
                        <MenuItem key={device.devEUI} value={device}>{device.name}</MenuItem>
                      ))}
                  </Select>}
                label="Device: "
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.autoRefresh}
                    onChange={this.handleCheck('autoRefresh')}
                    value="autoRefresh"
                    color="primary"
                  />
                }
                label="Auto Refresh"
                labelPlacement="start"
              />
              {autoRefresh &&
                <FormControlLabel
                  control={
                    <Select
                      className={classes.selectField}
                      value={this.state.refreshSeconds}
                      onChange={this.handleChangeRefresh}
                      inputProps={{
                        name: 'refreshTime',
                        id: 'refreshTime',
                      }}
                    >
                      <MenuItem key="5" value="5">5 Seconds</MenuItem>
                      <MenuItem key="10" value="10">10 Seconds</MenuItem>
                      <MenuItem key="60" value="60">1 Minute</MenuItem>
                      <MenuItem key="300" value="300">5 Minutes</MenuItem>
                    </Select>}
                  label="Interval"
                  labelPlacement="start"
                />
              }
              <Button
                style={{marginLeft:16}}
                variant="contained"
                endIcon={<RefreshIcon />}
                onClick={this.fetchData}
              >
                Refresh Now
              </Button>
            </Paper>
          )}
          <Paper className={classes.paper}>
            <FormControlLabel
              control={
              <Select
                className={classes.selectField}
                value={this.state.timeFilter}
                onChange={this.handleChangeTimeFilter}
                inputProps={{
                  name: 'timeFilter',
                  id: 'timeFilter',
                }}
              >
                <MenuItem key="10" value="10">Last 10 Minutes</MenuItem>
                <MenuItem key="30" value="30">Last 30 Minutes</MenuItem>
                <MenuItem key="60" value="60">Last 1 Hour</MenuItem>
                <MenuItem key="360" value="360">Last 6 Hours</MenuItem>
                <MenuItem key="720" value="720">Last 12 Hours</MenuItem>
                <MenuItem key="1440" value="1440">Last 24 Hours</MenuItem>
                <MenuItem key="0" value="0">Custom...</MenuItem>
              </Select>}
              label="Range: "
              labelPlacement="start"
          />
          {showTimeInputs &&
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Datetime 
                value={moment(timeLoText)}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm:ss"
                onChange={this.handleChangeTimeLo}
                // closeOnSelect={true}
                className={classes.textField}
                inputProps={{ className: timeLoClass }}
              />}
              label="From:"
              labelPlacement="start"
            />
          }

            {showTimeInputs &&
              <FormControlLabel
              className={classes.formControlLabel}
              control={<Datetime 
                value={moment(timeHiText)}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm:ss"
                onChange={this.handleChangeTimeHi}
                // closeOnSelect={true}
                className={classes.textField}
                inputProps={{ className: timeHiClass }}
              />}
              label="To:"
              labelPlacement="start"
            />
            }
          </Paper>
        </Grid>
        <Grid container spacing={4}>
          {gridItems}
        </Grid>
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(Dashboard);
