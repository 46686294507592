import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import SystemSettingsForm from "../system/SystemSettingsForm";
import AircomSystemStore from "../../stores/AircomSystemStore";
// import moment from "moment";

const styles = {
  card: {
    overflow: "visible",
  },
};


class UpdateSystemSettings extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {};
    // this.pauseGroup = this.pauseGroup.bind(this);
  }

  componentDidMount() {
    // system settings id is always 1
    AircomSystemStore.getSystemSettings(1, resp => {
      this.setState({
        systemSettings: resp.systemSettings
      });
    });
  }

  onSubmit(systemSettings) {
    AircomSystemStore.updateSystemSettings(systemSettings, () => {
    });
  }

  render() {
    const {systemSettings} = this.state;
    if (systemSettings === undefined) {
      return null;
    }
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <SystemSettingsForm
                submitLabel="Update system settings"
                object={this.state.systemSettings}
                onSubmit={this.onSubmit}
                match={this.props.match}
                update={true}
                disabled={!this.props.admin}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

UpdateSystemSettings.propTypes = {
  admin: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(UpdateSystemSettings));
