import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Grid from '@material-ui/core/Grid';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import SessionStore from "../../stores/SessionStore";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import theme from "../../theme";
import PacketDecoderForm from "./PacketDecoderForm";
import PacketEncoderForm from "./PacketEncoderForm";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class UtilsForm extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.locationToTab = this.locationToTab.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin(),
    });
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.endsWith("/encoder")) {
      tab = 1;
    }

    // if (window.location.href.endsWith("/settings")) {
    //   tab = 2;
    // }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="Utilities" />
        </TitleBar>
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab value={0} label="Packet Decoder" component={Link} to={"/utils"} />
            <Tab value={1} label="Packet Encoder" component={Link} to={"/utils/encoder"} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Route exact path={"/utils/encoder"} render={props => <PacketEncoderForm {...props} />} />
          <Route exact path={"/utils"} render={props => <PacketDecoderForm {...props} />} />
        </Grid>
      </Grid>
    );
  }
}

UtilsForm.propTypes = {
  classes: PropTypes.any.isRequired,
  // match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(UtilsForm));
