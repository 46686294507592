import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import ApplicationStore from "../../stores/ApplicationStore";
import AppDeviceGroupPollStore from "../../stores/AppDeviceGroupPollStore";
import DeviceGroupPollForm from "./DeviceGroupPollForm";

const styles = {
  card: {
    overflow: "visible",
  },
};


class CreateDeviceGroupPoll extends Component {
  constructor() {
    super();
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });
  }

  onSubmit(item) {
    let newItem = item;
    newItem.appDeviceGroupID = this.props.match.params.deviceGroupID;
    if (!item.pollNumber || item.pollNumber < 1 || item.pollNumber > 10) {
      return; // TODO: Show message to user?
    }

    AppDeviceGroupPollStore.create(newItem, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/${this.props.match.params.deviceGroupID}/polls`);
    });
  }

  render() {
    if (this.state.application === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="Create Poll" />
        </TitleBar>

        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <DeviceGroupPollForm
                submitLabel="Create poll"
                onSubmit={this.onSubmit}
                match={this.props.match}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(CreateDeviceGroupPoll));
