import React, { Component } from "react";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import MaskedInput from "react-text-mask";

class HEXMask extends Component {
  render() {
    const { inputRef, ...other } = this.props;
    
    // Populate mask array (50 bytes)
    let maskArray = [
      /[A-Fa-f0-9]/,
      /[A-Fa-f0-9]/,
    ];
    for (let i = 0; i < 49; i++) {
      maskArray.push(' ');
      maskArray.push(/[A-Fa-f0-9]/);
      maskArray.push(/[A-Fa-f0-9]/);
    }

    return(
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={maskArray}
      />
    );
  }
}

HEXMask.propTypes = {
  inputRef: PropTypes.any.isRequired,
}

class HexTextField extends Component {
  constructor() {
    super();

    this.state = {
      value: "",
    };
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });

    let str = "";

    const bytes = e.target.value.match(/[A-Fa-f0-9]{2}/g);
    if (bytes !== null) {
      str = bytes.join("");
    } else {
      str = "";
    }

    this.props.onChange({
      target: {
        value: str,
        type: "text",
        id: this.props.id,
      },
    });
  }

  componentDidMount() {
    this.setState({
      value: this.props.value || "",
    });
  }

  render() {
    return(
      <TextField
        type="text"
        InputProps={{
          inputComponent: HEXMask,
        }}
        {...this.props}
        onChange={this.onChange}
        value={this.state.value}
        disabled={this.props.disabled}
      />
    );
  }
}

HexTextField.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

HexTextField.defaultProps = {
  disabled: false,
}

export default HexTextField;
