import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';

import { VictoryLine, VictoryScatter, VictoryChart, VictoryAxis, VictoryTheme, VictoryBar } from 'victory';
import { floatToLocString } from '../styles/formatters';
import { styles } from '../styles/aircom-styles';
import moment from 'moment';

function ValueGraph(props) {
  const { classes} = props;
  // const { title = value } = props;
  let { graphData } = props;
  let timeLo = moment(props.timeLo);
  let timeHi = moment(props.timeHi);
  
  // const noDataResult = <p>No graph data for {value}</p>;
  const noDataResult = null;

  if (!Array.isArray(graphData) || (graphData.length === 0) || graphData[0].length === 0) {
    return noDataResult;
  }

  // Simplify data and convert date string to value
  // We only need the received_at and value field (depend on value property), and only if the value is present

  // 18-5-2021 graphData is now array with one or two data sets to facilitate 2 y axes
  // See https://formidable.com/open-source/victory/gallery/multiple-dependent-axes/
  
  const barGraphs = graphData.map(dataset => dataset[0].format.startsWith('CP_'));
  // Simplify data and convert timestamp string to moment object
  graphData = graphData.map(
    (dataset) => dataset.map(row => {
      const received_at = moment(row['receivedAt']);
      return { received_at, value: row['value'] };
    })
  );

  const minValues = graphData.map(
    (dataset) => Math.min(...dataset.map((d) => d.value))
  );
  let maxValues = graphData.map(
    (dataset) => Math.max(...dataset.map((d) => d.value))
  );
  maxValues = maxValues.map((value, i) => {
    if (value <= minValues[i]) {
      return value * 1.01; // We don't want min and max to be the same
    }
    return value;
  })

  const minTimes = graphData.map(
    (dataset) => Math.min(...dataset.map((d) => d.received_at))
  );
  const maxTimes = graphData.map(
    (dataset) => Math.max(...dataset.map((d) => d.received_at))
  );

  const newTimeLo = Math.min(...minTimes);
  if (newTimeLo > timeLo) {
    timeLo = newTimeLo;
  }
  const newTimeHi = Math.max(...maxTimes);
  if (newTimeHi < timeHi) {
    timeHi = newTimeHi;
  }
  // const valueLo = Math.min(...minValues);
  // const valueHi = Math.max(...maxValues);
  const orientations = ["left", "right"];
  const colors = ["blue", "red"];
  const anchors = ["end", "start"];

  return (
    <div
      className={classes.graphDiv}
    >
      <div>
        {/* <Typography variant="h5" color="inherit">
          {title}
        </Typography> */}

        <VictoryChart
            theme={VictoryTheme.material}
            scale={{x: "time", y: "linear"}}
            domain={{ x: [timeLo, timeHi], y: [0, 1] }}
            domainPadding={{x: [5, 5]}}
            height={250}
          >
        <VictoryAxis
            scale={{ x: "time" }}
            tickCount={5}
            tickFormat={(x) => {
              const aDate = new Date(x);
              return aDate.toLocaleDateString() + "\n" + aDate.getHours() + ":" + (aDate.getMinutes()<10?'0':'') + aDate.getMinutes();
              //return unixDateToLocString(aDate.valueOf());
            }}
            style={{
              tickLabels: {fontSize: 7}
            }}
          />
          {graphData.map((dataset, i) => {
            return (
              <VictoryAxis
                dependentAxis  
                // label={value}
                key={i}
                orientation={orientations[i]}
                tickFormat={(t) => floatToLocString((t * (maxValues[i] - minValues[i])) + minValues[i], 2)}
                tickCount={5}
                style={{
                  axis: {stroke: colors[i]},
                  ticks: {padding: 6},
                  axisLabel: {fill: "#0000FF", fontSize: 9},
                  tickLabels: {fill: colors[i], fontSize: 7, textAnchor: anchors[i]}
                }}
              />
            )
          })}

          {graphData.map((dataset, i) => {
            if (barGraphs[i]) {
              return (
                <VictoryBar
                  data={dataset}
                  x="received_at"
                  y={(datum) => (datum.value - minValues[i]) / (maxValues[i] - minValues[i])}
                  alignment="start"
                  // barWidth="50"
                />
              )
            } else {
              return (
                <VictoryLine
                data={dataset}
                x="received_at"
                y={(datum) => (datum.value - minValues[i]) / (maxValues[i] - minValues[i])}
                style={{
                  data: {
                    stroke: colors[i], strokeWidth: 1
                  },
                }}
                />
              )
            }
          })}
          {graphData.map((data, i) => {
            return (
              <VictoryScatter
                  data={data}
                  x="received_at"
                  y={(datum) => (datum.value - minValues[i]) / (maxValues[i] - minValues[i])}
                  style={{
                    data: {
                      stroke: colors[i],
                      fill: 'white',
                      strokeWidth: 1,
                    },
                    labels: { fill: colors[i], display: 'none'}
                  }}
                  labels={(d) => floatToLocString(Number(d.datum.value), 2)}
                  events={[{
                    target: "data",
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            target: "data",
                            mutation: (props) => {
                              const fill = props.style && props.style.fill;
                              return fill === colors[i] ? { style: { fill: "white", stroke: colors[i], strokeWidth: 1} } : { style: { fill: colors[i], stroke: colors[i], strokeWidth: 1 } };
                            }
                          }, {
                            target: "labels",
                            mutation: (props) => {
                              return props.style.display === 'none' ? { style: { ...props.style, display: 'initial', fontSize: 8}} : { style: { ...props.style, display: 'none'}};
                            }
                          }
                        ];
                      }
                    }
                  }]}
                />
            )
          })}

        </VictoryChart>
      </div>
    </div>
  );
};

ValueGraph.propTypes = {
  classes: PropTypes.any.isRequired,
  graphData: PropTypes.any.isRequired,
  style: PropTypes.any.isRequired,
  timeHi: PropTypes.string.isRequired,
  timeLo: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
  // value: PropTypes.any.isRequired,
}

export default withStyles(styles)(ValueGraph);
