import React from "react";

import { withStyles } from "@material-ui/core/styles";
// import TextField from '@material-ui/core/TextField';
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";
import FormComponent from "../../classes/FormComponent";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import Form from "../../components/Form";

const styles = {
  formLabel: {
    fontSize: 12,
  },
};


class AppDeviceForm extends FormComponent {
  constructor() {
    super();
    this.getAppDeviceGroupOption = this.getAppDeviceGroupOption.bind(this);
    this.getAppDeviceGroupOptions = this.getAppDeviceGroupOptions.bind(this);

    this.state = {};
  }

  getAppDeviceGroupOption(id, callbackFunc) {
    AppDeviceGroupStore.get(id, resp => {
      callbackFunc({label: resp.appDeviceGroup.name, value: resp.appDeviceGroup.id});
    });
  }

  getAppDeviceGroupOptions(search, callbackFunc) {
    AppDeviceGroupStore.list(this.state.object.applicationID,999,0, resp => {
      const options = resp.result.map((ag) => {return {label: ag.name, value: ag.id}});
      callbackFunc(options);
    });
  }

  //componentDidMount() {
  //  super.componentDidMount();
  //}

  // componentDidUpdate(prevProps) {
  //   super.componentDidUpdate(prevProps);
  // }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <div>
        <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Device Type</FormLabel>
            <AutocompleteSelect
                id="appDeviceGroupID"
                label="Device Type"
                value={this.state.object.appDeviceGroupID}
                onChange={this.onChange}
                getOption={this.getAppDeviceGroupOption}
                getOptions={this.getAppDeviceGroupOptions}
                helperText="Device Type"
                required
                fullWidth
                disabled={this.props.disabled}
            />
        </FormControl>
        </div>
      </Form>
    );
  }
}

export default withStyles(styles)(AppDeviceForm);
