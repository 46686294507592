/**
*  Library to convert dates and numbers to/from strings
*/
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment';
import numeral from 'numeral';

export function formatReading(float, format) {
  if (format === "on-off") {
    return float !== 0 ? "On" : "Off";
  }
  if (format === "Time") {
    return moment(float * 1000).format('YYYY-MM-DD HH:mm:ss');
  }
  return numeral(float).format(format);
}

export function formatTimestamp(aDate) {
  return moment(aDate).format('YYYY-MM-DD HH:mm:ss');
}

export function floatToLocString(float, decPlaces) {
  let mask = '0';
  if (decPlaces > 0) {
    mask = `0.${'0'.repeat(decPlaces)}`;
  }
  return numeral(float).format(mask);
}

// export function unixMsToLocString(unixMs = 0) {
//   const aDate = new Date(unixMs);
//   return moment(aDate).format('LL') + " " + moment(aDate).format('LTS');
// }

// export function unixMsToCSVString(unixMs = 0) {
//   const aDate = new Date(unixMs);
//   return moment(aDate).format('YYYY-MM-DD HH:mm:ss');
// }

// export function unixMsToTimeString(unixMs = 0) {
//   const aDate = new Date(unixMs);
//   return moment(aDate).format('HH:mm');
// }

// export function unixUtcMsToTimeString(unixMs = 0) {
//   //const aDate = new Date(unixMs);
//   return moment()
//   .utc()
//   .startOf('day')
//   .add(unixMs, 'milliseconds').local().format('HH:mm');
// }

// export function unixDateToLocString(unixDate) {
//   const aDate = new Date(unixDate * 1000);
//   return moment(aDate).format('LLL');
// }

// export function locStringToUnixMs(dateString) {
//   const aDate = new Date(dateString);
//   return aDate.valueOf();
// }

// export function intToLocString(int, scalePlaces, decPlaces) {
//   const num = int * (10 ** (-1 * scalePlaces));
//   let mask = '0';
//   if (decPlaces > 0) {
//     mask = `0.${'0'.repeat(decPlaces)}`;
//   }
//   return numeral(num).format(mask);
// }

// export function floatToLocString(float, decPlaces) {
//   let mask = '0';
//   if (decPlaces > 0) {
//     mask = `0.${'0'.repeat(decPlaces)}`;
//   }
//   return numeral(float).format(mask);
// }

// export function getLocTimeString(hours, mins) {
//   const aDate = new Date(0, 0, 0, hours, mins);
//   return moment(aDate).format('LT');
// }

// export function getFormattedString(aDate, format) {
//   return moment(aDate).format(format);
// }

// /**
//  * Return number of mins since 00:00 on given date
//  * Will never return zero. (Midnight = 1440)
//  * @param {*} date
//  */
// export function utcDateToMins(date) {
//   if (date == null) {
//     return 0; //not set
//   }
//   const dayStart = moment.utc( date ).startOf('day');
//   let result = moment.utc(date).diff(dayStart, 'minutes');
//   if (result === 0) {
//     result = 1440; // Zero means undefined
//   }
//   return result;
// }

// export function utcMinsToDate(mins) {
//   if (mins == null || mins === 0) {
//     return null; //not set
//   }
//   return moment.utc().startOf('day').add(mins,'minutes').local().format('HH:mm');
// }