import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";

import ApplicationStore from "../../stores/ApplicationStore";
import AppDeviceGroupPollStore from "../../stores/AppDeviceGroupPollStore";
import SessionStore from "../../stores/SessionStore";
import DeviceAdmin from "../../components/DeviceAdmin";

import theme from "../../theme";
import UpdateDeviceGroupPoll from "./UpdateDeviceGroupPoll";

import Back from "mdi-material-ui/ArrowLeft";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class DeviceGroupPollLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.deleteDeviceGroupPoll = this.deleteDeviceGroupPoll.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    AppDeviceGroupPollStore.get(this.props.match.params.deviceGroupPollID, resp => {
      this.setState({
        deviceGroupPoll: resp.appDeviceGroupPoll,
      });
    });

    SessionStore.on("change", this.setIsAdmin);

    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
    // DeviceStore.removeListener("update", this.getDevice);
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationDeviceAdmin(this.props.match.params.organizationID),
    }, () => {
    });
  }

  deleteDeviceGroupPoll() {
    if (window.confirm("Are you sure you want to delete this device type poll?")) {
      AppDeviceGroupPollStore.delete(this.props.match.params.deviceGroupPollID, resp => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/${this.props.match.params.deviceGroupID}/polls`);
      });
    }
  }

  render() {
    if (this.state.application === undefined || this.state.deviceGroupPoll === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <DeviceAdmin organizationID={this.props.match.params.organizationID}>
              <TitleBarButton
                label="Poll List"
                icon={<Back/>}
                color="primary"
                to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/${this.props.match.params.deviceGroupID}/polls`}
              />
              <TitleBarButton
                label="Delete Poll"
                icon={<Delete/>}
                color="secondary"
                onClick={this.deleteDeviceGroupPoll}
              />
            </DeviceAdmin>
          }
        >
          <TitleBarTitle title="Device Type Poll" />
        </TitleBar>

        <Grid item xs={12}>
          <Route exact path={`${this.props.match.path}`} render={props => <UpdateDeviceGroupPoll deviceGroupPoll={this.state.deviceGroupPoll} admin={this.state.admin} {...props} />} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(DeviceGroupPollLayout));
