import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import DeviceGroupForm from "./DeviceGroupForm";
import PauseDeviceGroupForm from "./PauseDeviceGroupForm";
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";

const styles = {
  card: {
    overflow: "visible",
  },
};


class UpdateDeviceGroup extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.pauseGroup = this.pauseGroup.bind(this);
  }

  onSubmit(deviceGroup) {
    AppDeviceGroupStore.update(deviceGroup, resp => {
    });
  }

  pauseGroup(deviceGroup) {
    AppDeviceGroupStore.pause(deviceGroup.id, (deviceGroup.pausedAt ? false : true) , resp => {
      this.props.reloadRequestCallback();
    });
  }

  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <DeviceGroupForm
                submitLabel="Update device type"
                object={this.props.deviceGroup}
                onSubmit={this.onSubmit}
                match={this.props.match}
                update={true}
                disabled={!this.props.admin}
              />
              <PauseDeviceGroupForm
                object={this.props.deviceGroup}
                onSubmit={this.pauseGroup}
                match={this.props.match}
                disabled={!this.props.admin}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(UpdateDeviceGroup));
