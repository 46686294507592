import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import ListDeviceGroups from "../device-groups/ListDeviceGroups";
import ApplicationStore from "../../stores/ApplicationStore";

// import theme from "../../theme";


class ApplicationDeviceGroups extends Component {
  constructor() {
    super();
    this.state = {
      application: null,
    };

  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });
  }

  render() {
    if (!this.state.application) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
      <TitleBar>
        <TitleBarTitle title={`Application Device Types (${this.state.application.application.name})`} />
      </TitleBar>

      <Grid item xs={12}>
        <ListDeviceGroups application={this.state.application.application} {...this.props} />
      </Grid>
    </Grid>
    );
  }
}

ApplicationDeviceGroups.propTypes = {
  match: PropTypes.any.isRequired
}

export default ApplicationDeviceGroups;
