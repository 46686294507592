import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
// import TitleBarButton from "../../components/TitleBarButton";
// import Admin from "../../components/Admin";
import Button from '@material-ui/core/Button';

import ApplicationStore from "../../stores/ApplicationStore";
import AircomSystemStore from "../../stores/AircomSystemStore";
import SessionStore from "../../stores/SessionStore";
import UpdateApplication from "./UpdateApplication";
import ListApplicationItems from "./ListApplicationItems";
import ListIntegrations from "./ListIntegrations";
import CreateHTTPIntegration from "./integrations/CreateHTTPIntegration";
import UpdateHTTPIntegration from "./integrations/UpdateHTTPIntegration";

import theme from "../../theme";


const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class ApplicationLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.deleteApplication = this.deleteApplication.bind(this);
    this.locationToTab = this.locationToTab.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    SessionStore.on("change", this.setIsAdmin);

    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  deleteApplication() {
    if (window.confirm("Are you sure you want to delete this application? This will also delete all devices part of this application.")) {
      // Sidenav will detect deletion and deal with it
      AircomSystemStore.deleteApplication(this.props.match.params.applicationID, () => {});
    }
  }

  locationToTab() {
    let tab = 0;

    // If not admin, no tab will be selected so no need to check admin here
    if (window.location.href.endsWith("/details")) {
      tab = 1;
    }

    if (window.location.href.includes("/integrations")) {
      tab = 2;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.application === undefined) {
      return(<div></div>);
    }
    const {admin} = this.state;
    const showDeleteButton = window.location.href.endsWith("/details") && admin;

    return(
      <Grid container spacing={4}>
        <TitleBar
          // buttons={
          //   <Admin organizationID={this.props.match.params.organizationID}>
          //     <TitleBarButton
          //       label="Delete Application"
          //       icon={<Delete />}
          //       color="secondary"
          //       onClick={this.deleteApplication}
          //     />
          //   </Admin>
          // }
        >
          <TitleBarTitle title={`Application (${this.state.application.application.name})`} />
        </TitleBar>
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab value={0} label="Items" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`} />
            {admin && <Tab value={1} label="Details" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/details`} />}
            {admin && <Tab value={2} label="Integrations" component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/integrations`} />}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {admin && <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/details`} render={props => <UpdateApplication application={this.state.application.application} {...props} />} />}
          {admin && <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/integrations`} render={props => <ListIntegrations application={this.state.application.application} {...props} />} /> }
          {admin && <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/integrations/http/create`} render={props => <CreateHTTPIntegration application={this.state.application.application} {...props} />} />}
          {admin && <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/integrations/http/edit`} render={props => <UpdateHTTPIntegration application={this.state.application.application} {...props} />} />}
          <Route exact path={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`} render={props => <ListApplicationItems application={this.state.application.application} {...props} />} />
        </Grid>
        {showDeleteButton && (
          <Grid item xs={12} className={this.props.classes.buttons}>
            <Button variant="outlined" className={this.props.classes.button} color="secondary" onClick={this.deleteApplication} >
              <Delete className={this.props.classes.icon} />
                Delete Application
            </Button>
          </Grid>)}
      </Grid>
    );
  }
}

ApplicationLayout.propTypes = {
  classes: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(ApplicationLayout));
