// import {unixMsToCSVString /*, unixMsToTimeString, unixUtcMsToTimeString*/} from '../i18n/i18nhelpers';
import moment from "moment";

const MAX_POLLED_READINGS = 20;
export const MAX_PACKET_BYTES = 50;

export function unixMsToCSVString(unixMs = 0) {
  const aDate = new Date(unixMs);
  return moment(aDate).format('YYYY-MM-DD HH:mm:ss');
}

function checkBit(number, bitPos)
{
  // eslint-disable-next-line no-bitwise
  return (number & (1 << bitPos)) === 0 ? 0 : 1;
}

export function setBit(value, bitNum) {
  // eslint-disable-next-line no-bitwise
  var mask = 1 << bitNum;
  let result = value || 0;
  // eslint-disable-next-line no-bitwise
  result |= mask;
  return result;
}

export function clearBit(value, bitNum) {
  // eslint-disable-next-line no-bitwise
  var mask = 1 << bitNum;
  let result = value || 0;
  // eslint-disable-next-line no-bitwise
  result &= ~mask;
  return result;
}

/**
 * 
 * @param {*} buffer 
 * @returns
 * Returns expected fields with value set to null
 * This means we can tell if expected item is missing 
 */
function prepopulateContents(buffer)
{
  var result = {};

  if (buffer.length < 1) { //nothing
    return result;
  }

  result.validHeader = true;
  result.headerLength = 1;
  for (let i = 0; i < buffer.length; i++) {
    if (checkBit(buffer.readUInt8(i), 7) === 1) {
      if ((buffer.length - 1) > i) {
        result.headerLength += 1;
      } else {
        // Next byte of header is missing
        result.validHeader = false;
        break;
      }
    } else {
      // Last bit not set, so no more header
      break;
    }
  }

  const headerByte1 = buffer.readUInt8(0);
  if (checkBit(headerByte1, 0) === 1) {
    result.digitalOnly = 1;
    result.d1 = checkBit(headerByte1, 1);
    result.d2 = checkBit(headerByte1, 2);
    result.d3 = checkBit(headerByte1, 3);
    result.d4 = checkBit(headerByte1, 4);
    // Valid digital-only packet must be between 1 and 4 bytes long
    // With last bit of each header byte set

    result.validPacket = result.validHeader && (buffer.length <= 4);

    if (result.validPacket) {
      if (buffer.length > 1) {
        result.maxPolledFlags = (buffer.length - 1) * 8;  
        if (result.maxPolledFlags > MAX_POLLED_READINGS) {
          result.maxPolledFlags = MAX_POLLED_READINGS
        }
      }
    }

    return result;
  }

  result.digitalOnly = 0;
  if (checkBit(headerByte1, 1) === 1) {
    // Include digitals
    result.d1 = null;
    result.d2 = null;
    result.d3 = null;
    result.d4 = null;
  }

  if (checkBit(headerByte1, 2) === 1) {
    result.a1 = null;
  }
  
  if (checkBit(headerByte1, 3) === 1) {
    result.a2 = null;
  }
  
  if (checkBit(headerByte1, 4) === 1) {
    result.c1 = null;
  }
  
  if (checkBit(headerByte1, 5) === 1) {
    result.c2 = null;
  }
  
  if (checkBit(headerByte1, 6) === 1) {
    result.bv = null;
    result.temp = null;
    result.time = null;
  };

  if ((checkBit(headerByte1, 7) !== 1) || (buffer.length < 2)) {
    return result;  
  }

  const headerByte2 = buffer.readUInt8(1);
  result.headerLength = 2;

  if (checkBit(headerByte2, 0) === 1) {
    result.modbusPacketSize = null;
  }
  
  if (checkBit(headerByte2, 1) === 1) {
    result.a3 = null;
  }

  if (checkBit(headerByte2, 2) === 1) {
    result.a4 = null;
  }

  if (checkBit(headerByte2, 3) === 1) {
    result.a5 = null;
  }

  return result;
}

/**
 * 
 * @param {*} payloadHex 
 */
export function decodePayload(payloadHex) {
  const buffer = Buffer.from(payloadHex, 'hex');

  //new packet structure, packet versions 1 and 2 have been dropped

  if (buffer.length < 1) { //nothing
    return {
      validPacket: false
    };
  }

  let result = prepopulateContents(buffer);

  if (!result.validHeader) {
    result.validPacket = false;
    return result;
  }

  if (result.digitalOnly === 1) {
    return result;
  }

  result.validPacket = false;

  var startPos = result.headerLength;
  const packetLength = buffer.length;

  if ((packetLength - startPos) < 1) {
    return result;
  }

  if (result.d1 === null) {
    const digitals = buffer.readUInt8(startPos);
    result.d1 = checkBit(digitals, 0);
    result.d2 = checkBit(digitals, 1);
    result.d3 = checkBit(digitals, 2);
    result.d4 = checkBit(digitals, 3);
    startPos = startPos + 1;
  }

  if (result.a1 === null) {
    if ((packetLength - startPos) >= 4) {
      result.a1 = buffer.readFloatLE(startPos)
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.a2 === null) {
    if ((packetLength - startPos) >= 4) {
      result.a2 = buffer.readFloatLE(startPos)
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.a3 === null) {
    if ((packetLength - startPos) >= 4) {
      result.a3 = buffer.readFloatLE(startPos)
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.a4 === null) {
    if ((packetLength - startPos) >= 4) {
      result.a4 = buffer.readFloatLE(startPos)
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.a5 === null) {
    if ((packetLength - startPos) >= 4) {
      result.a5 = buffer.readFloatLE(startPos)
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.c1 === null) {
    if ((packetLength - startPos) >= 4) {
      result.c1 = buffer.readUInt32LE(startPos);
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.c2 === null) {
    if ((packetLength - startPos) >= 4) {
      result.c2 = buffer.readUInt32LE(startPos);
      startPos = startPos + 4;
    } else {
      return result;
    }
  }

  if (result.bv === null) {
    if ((packetLength - startPos) >= 6) {
      result.bv = (buffer.readUInt8(startPos) * 0.01) + 1.5;
      result.temp = (buffer.readUInt8(startPos + 1) * 0.5) - 20;
      result.time = buffer.readUInt32LE(startPos + 2);
      startPos = startPos + 6;
    } else {
      return result;
    }
  }

  if (result.modbusPacketSize === null) {
    // Must be at least modbus packet size and 2-byte modbus header
    let endPos = 0;
    if ((packetLength - startPos) >= 3) {
      result.modbusPacketSize = buffer.readUInt8(startPos);
      endPos = startPos + result.modbusPacketSize + 1;
      startPos = startPos + 1;
    } else {
      return result;
    }
    if ((packetLength - endPos) !== 0) {
      return result;
    }

    const modbusHeader = buffer.readUInt16BE(startPos);
    for (let i = 0; i < 10; i++) {
      if (checkBit(modbusHeader, i) === 1) {
        result[`modbusPoll${i + 1}`] = 1;
      }
    }

    startPos = endPos;
  }

  if ((packetLength - startPos) === 0) {
    result.validPacket = 1;
  }
  
  return result;
}

/**
 * 
 * @param {*} payloadFields 
 */
export function encodePayload(payloadFields) {

  const buffer = Buffer.alloc(100); // Allow extra space to be safe

  let writePos = 0;
  let headerBytes = [0, 0, 0, 0];
  let headerByteCount = 1;

  // Analogues
  const {a1, a2, a3, a4, a5, c1, c2, d1, d2, d3, d4, bv, temp, time} = payloadFields;
  let digitalOnlyPacket = false;

  if (d1 !== null || d2 !== null || d3 !== null || d4 !== null) {
    digitalOnlyPacket = true;
    headerBytes[0] = setBit(headerBytes[0], 1);
    let digitalsByte = 0;
    if (d1) {
      digitalsByte = setBit(digitalsByte, 0);
    }
    if (d2) {
      digitalsByte = setBit(digitalsByte, 1);
    }
    if (d3) {
      digitalsByte = setBit(digitalsByte, 2);
    }
    if (d4) {
      digitalsByte = setBit(digitalsByte, 3);
    }
    buffer.writeUInt8(digitalsByte, writePos);
    writePos += 1;
  }
  
  if (a1 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerBytes[0] = setBit(headerBytes[0], 2);
    buffer.writeFloatLE(a1, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }
  if (a2 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerBytes[0] = setBit(headerBytes[0], 3);
    buffer.writeFloatLE(a2, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }

  if (a3 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerByteCount = 2;
    headerBytes[0] = setBit(headerBytes[0], 7);
    headerBytes[1] = setBit(headerBytes[1], 1);
    buffer.writeFloatLE(a3, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }
  if (a4 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerByteCount = 2;
    headerBytes[0] = setBit(headerBytes[0], 7);
    headerBytes[1] = setBit(headerBytes[1], 2);
    buffer.writeFloatLE(a4, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }
  if (a5 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerByteCount = 2;
    headerBytes[0] = setBit(headerBytes[0], 7);
    headerBytes[1] = setBit(headerBytes[1], 3);
    buffer.writeFloatLE(a5, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }

  if (c1 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerBytes[0] = setBit(headerBytes[0], 4);
    buffer.writeUInt32LE(c1, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }
  if (c2 !== null && (MAX_PACKET_BYTES - writePos - headerByteCount) >= 4) {
    headerBytes[0] = setBit(headerBytes[0], 5);
    buffer.writeUInt32LE(c2, writePos);
    writePos += 4;
    digitalOnlyPacket = false;
  }

  // Diagnostics
  if ((MAX_PACKET_BYTES - writePos - headerByteCount) >= 6 && (bv !== null || temp !== null || time !== null)) {
    headerBytes[0] = setBit(headerBytes[0], 6);
    digitalOnlyPacket = false;

    if (bv === null || bv < 1.5) {
      buffer.writeUInt8(0, writePos);
    } else if (bv > 4.05) {
      buffer.writeUInt8(255, writePos);
    } else {
      buffer.writeUInt8(Math.round(((bv - 1.5)/(4.05 - 1.5)) * 255), writePos);
    };
    writePos += 1;
  
    if (temp === null || temp < -20) {
      buffer.writeUInt8(0, writePos);
    } else if (temp > 107.5) {
      buffer.writeUInt8(255, writePos);
    } else {
      buffer.writeUInt8(Math.round(((temp + 20)/(107.5 + 20)) * 255), writePos);
    };
    writePos += 1;
  
    if (time === null || time < 0) {
      buffer.writeUInt32LE(0, writePos);
    } else if ((time * 0.001) > 4294967295) {
      buffer.writeUInt32LE(4294967295, writePos);
    } else {
      buffer.writeUInt32LE(Math.round(time * 0.001), writePos);
    }
    writePos += 4;
  }

  // Modbus next
  let modbusLength = 0;
  let modbusHeader = 0;
  let modbusBuffer;
  let modbusString = '';
  let thisVal;
  let modbusIncluded = false;
  let bytesRemaining = MAX_PACKET_BYTES - writePos - 5; // Allow 2 for header and 3 for modbus size and header
  for (let i = 1; i <= 10; i++) {
    thisVal = payloadFields[`modbusPoll${i}`];
    if (thisVal !== null) {
      modbusBuffer = Buffer.from(thisVal, 'hex');
      if (bytesRemaining >= modbusBuffer.length) {
        modbusHeader = setBit(modbusHeader, i - 1);
        headerByteCount = 2;
        headerBytes[1] = setBit(headerBytes[1], 0);
        modbusLength += modbusBuffer.length;
        bytesRemaining = bytesRemaining - modbusBuffer.length;
        modbusString = modbusString + modbusBuffer.toString('hex');
        modbusIncluded = true;
        digitalOnlyPacket = false;
      }
    }
  }
  if (modbusIncluded) {
    modbusLength += 2; // Include modbus header
    const Buf2 = Buffer.alloc(3);
    Buf2.writeUInt8(modbusLength, 0);
    Buf2.writeUInt16BE(modbusHeader, 1); // Big-endian
    modbusString = Buf2.toString('hex') + modbusString;
  }

  if (digitalOnlyPacket) {
    headerByteCount = 1;
    headerBytes[0] = setBit(headerBytes[0], 0);
    if (d1 !== null && d1 !== 0) {
      headerBytes[0] = setBit(headerBytes[0], 1);
    }
    if (d2 !== null && d2 !== 0) {
      headerBytes[0] = setBit(headerBytes[0], 2);
    }
    if (d3 !== null && d3 !== 0) {
      headerBytes[0] = setBit(headerBytes[0], 3);
    }
    if (d4 !== null && d4 !== 0) {
      headerBytes[0] = setBit(headerBytes[0], 4);
    }
  }

  if (headerByteCount > 1) {
    headerBytes[0] = setBit(headerBytes[0], 7);
  }
  const headerBuffer = Buffer.alloc(headerByteCount);
  headerBuffer.writeUInt8(headerBytes[0], 0);

  if (digitalOnlyPacket) {
    return headerBuffer.toString('hex');
  }

  if (headerByteCount >= 2) {
    headerBuffer.writeUInt8(headerBytes[1], 1);
  }
  return headerBuffer.toString('hex') + buffer.toString('hex', 0, writePos) + modbusString;
}