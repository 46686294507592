import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  formLabel: {
    fontSize: 12,
  },
};


class ApplicationItemForm extends FormComponent {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <div>
          <TextField
            id="name"
            label="Item name"
            helperText="The name may only contain letters, numbers and dashes."
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            fullWidth
            required
            disabled={this.props.disabled}
          />
          <TextField
            id="abbreviation"
            label="Item abbreviation"
            helperText="The name may only contain letters, numbers and dashes."
            margin="normal"
            value={this.state.object.abbreviation || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            fullWidth
            required
            disabled={this.props.disabled}
          />
          <TextField
            id="description"
            label="Item description"
            margin="normal"
            value={this.state.object.description || ""}
            onChange={this.onChange}
            fullWidth
            required
            disabled={this.props.disabled}
          />
          <TextField
            id="numberFormat"
            label="Number format"
            margin="normal"
            value={this.state.object.numberFormat || ""}
            onChange={this.onChange}
            fullWidth
            required
            disabled={this.props.disabled}
          />
          <TextField
            id="sortOrder"
            label="Sort order"
            type="number"
            margin="normal"
            value={this.state.object.sortOrder || 0}
            onChange={this.onChange}
            helperText="Sort order used for display purposes"
            required
            fullWidth
            disabled={this.props.disabled}
          />
          <FormGroup>
            <FormControlLabel
              label="Show on dashboard"
              control={
                <Checkbox
                  id="showOnDashboard"
                  checked={!!this.state.object.showOnDashboard}
                  onChange={this.onChange}
                  disabled={this.props.disabled}
                  value="true"
                  color="primary"
                />
              }
            />
          </FormGroup>
          <FormHelperText>Show on dashboard</FormHelperText>
        </div>
      </Form>
    );
  }
}

export default withStyles(styles)(ApplicationItemForm);
