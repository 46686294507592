import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
// import OrganizationStore from "../../stores/OrganizationStore";
import OrganizationUserForm from "./OrganizationUserForm";
import AircomUserStore from "../../stores/AircomUserStore"


class CreateOrganizationUser extends Component {
  constructor() {
    super();

    this.onAssignUser = this.onAssignUser.bind(this);
  }

  onAssignUser(user) {
    /*OrganizationStore.addUser(this.props.match.params.organizationID, user, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users`);
    });*/
    user.organizationID = this.props.match.params.organizationID
    // user.password = "aircom"
    AircomUserStore.create(user, () => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users`);
    });
  };

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="Organization users" to={`/organizations/${this.props.match.params.organizationID}/users`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Add" />
        </TitleBar>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <OrganizationUserForm
                submitLabel="Add user"
                onSubmit={this.onAssignUser}
                update={false}
              />
            </CardContent>
          </Card>

        </Grid>
      </Grid>
    );
  }
}

CreateOrganizationUser.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
}

export default withRouter(CreateOrganizationUser);
