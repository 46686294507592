import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DigitalIcon from '@material-ui/icons/RadioButtonChecked';
import { styles } from '../styles/aircom-styles';
import {formatReading, formatTimestamp} from '../styles/formatters';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import UpPacketStore from "../stores/UpPacketStore";

export class DeviceReadingsCards extends Component {
  constructor() {
    super();

    this.state = {
      fetchingData: false,
      data: null
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidUpdate(prevProps){
    if ((prevProps === this.props) || this.state.fetchingData) {
      return;
    }

    //only do this if any of the filter has changed, otherwise this is called recursively (timeHi only means that the refresh time has occured)
    if (
      (prevProps.deviceFilter !== this.props.deviceFilter) ||
        (prevProps.timeHi !== this.props.timeHi) ||
        (prevProps.timeLo !== this.props.timeLo) ||
        (prevProps.appItemIds !== this.props.appItemIds)
    ) {
      this.fetchData(prevProps.deviceFilter !== this.props.deviceFilter); // Clear so previous data no longer showing
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(clearDataFirst) {
    const data = (clearDataFirst === true) ? null : this.state.data;
    this.setState({fetchingData: true, data}, () => {
      const {deviceFilter, applicationID, appItemIds} = this.props;
      UpPacketStore.getLatestReadings(applicationID,"",deviceFilter, resp => {
        let data = resp.items.filter((value) => appItemIds.includes(parseInt(value.appItemId, 10)));
        this.setState({data, fetchingData: false});
      });
    })
  }

  render() {
    const {deviceFilter, filterName, classes} = this.props;
    const {data} = this.state;
    if (!deviceFilter) {
      return null;
    }

    return(
      <Grid item xs={12}>
        <Typography variant="h5" color="inherit">
          Latest Measurements ({filterName ? filterName : 'All Devices'})
          {this.state.fetchingData ? "..." : ""}
        </Typography>
        <div className={styles.lastPacketContainer}>
        <Grid
          container
          direction="row"
          spacing={1}
        >
          {!data && (
            <Grid item>
              <Typography>
                Loading data...
              </Typography>
            </Grid>
          )}
          {data && (
            data.map((value) => {
              let valueComp;

              if (value.deviceReadings.length > 0)
              {
                if (value.format === "on-off") {
                  let iconClass = classes.greyDigital;
                  if (Array.isArray(value.deviceReadings) && (value.deviceReadings.length > 0)) {
                    iconClass = value.deviceReadings[0].value === 0 ? classes.redDigital : classes.greenDigital;
                  }
                  valueComp = <DigitalIcon className={iconClass}></DigitalIcon>
                } else {
                  valueComp = (
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                      {value.deviceReadings[0] ? formatReading(value.deviceReadings[0].value, value.format) : ""}
                    </Typography>
                  );
                }
                if (!value.deviceReadings[0].receivedAt) {
                  return null;
                }
              } else
                return null;
              
              const timestampText = value.deviceReadings[0] ? formatTimestamp(value.deviceReadings[0].receivedAt) : "No data";
              return (
                <Grid item>
                  <Card className={styles.card}>
                    <CardContent>
                      <Typography className={styles.cardTitle} color="textSecondary" gutterBottom>
                        {value.appItemName}
                      </Typography>
                      {(value.deviceReadings.length > 0 && value.deviceReadings[0].receivedAt ? valueComp : "")}
                      <Typography className={styles.cardTime} color="textSecondary">
                        {(value.deviceReadings.length > 0 && value.deviceReadings[0].receivedAt ? timestampText : "No Data Received")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid> 
              )
            })
          )}
        </Grid>
        </div>
      </Grid>
    );
  }
}

DeviceReadingsCards.propTypes = {
  appItemIds: PropTypes.array.isRequired,
  applicationID: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  deviceFilter: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  timeLo: PropTypes.string.isRequired,
  timeHi: PropTypes.string.isRequired,
};

export default withStyles(styles)(DeviceReadingsCards);
