import React, { Component } from "react";
import {Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import OrganizationStore from "../../stores/OrganizationStore";

import theme from "../../theme";

// import HomeIcon from "mdi-material-ui/Home";
// import AccountIcon from "mdi-material-ui/Account";
// import AppsIcon from "mdi-material-ui/Apps";
// import RadioTowerIcon from "mdi-material-ui/RadioTower";
import TuneIcon from "mdi-material-ui/Tune";
// import RssIcon from "mdi-material-ui/Rss";
import AccountDetailsIcon from "mdi-material-ui/AccountDetails";
// import KeyVariantIcon from "mdi-material-ui/KeyVariant";
// import EditIcon from "@material-ui/icons/Edit";

import { Button } from "@material-ui/core";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class OrganizationSetup extends Component {
  constructor() {
    super();
    this.state = {
      organization: null,
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }
    this.loadData();
  }

  loadData = () => {
    this.setState({organization: null}, () => {
      OrganizationStore.get(this.props.match.params.organizationID, organization => {
        this.setState({organization});
      });
    })
  }

  render() {
    const {organization} = this.state;
    if (!organization) {
      return null;
    }

    return(
    <Grid container spacing={4}>
      <TitleBar>
        <TitleBarTitle title={"Organization Setup (" + organization.organization.name +  ")"} />
      </TitleBar>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          {/* <Button startIcon={<EditIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/edit'}>
            Basic Configuration
          </Button> */}
          {/* <Button startIcon={<AccountIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/users'}>
            Org. Users
          </Button> */}
          {/* <Button startIcon={<KeyVariantIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/api-keys'}>
            Org. API Keys
          </Button> */}
          <Button startIcon={<AccountDetailsIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/service-profiles'}>
            Org. Service Profiles
          </Button>
          <Button startIcon={<TuneIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/device-profiles'}>
            Org. Device Profiles
          </Button>
          {/* <Button startIcon={<RadioTowerIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/gateways'}>
            Org. Gateways
          </Button> */}
          {/* <Button startIcon={<AppsIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/applications'}>
            Org. Applications
          </Button> */}
          {/* <Button startIcon={<RssIcon />} style={{margin: 8}} variant="contained" component={Link} to={'/organizations/' + this.state.organization.organization.id + '/multicast-groups'}>
            Org. Multicast Groups
          </Button> */}
        </Grid>
      </Grid>
    </Grid>
    );
  }
}

OrganizationSetup.propTypes = {
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(OrganizationSetup));
