import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";

import ApplicationStore from "../../stores/ApplicationStore";
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";
import SessionStore from "../../stores/SessionStore";
import DeviceAdmin from "../../components/DeviceAdmin";

import theme from "../../theme";
import UpdateDeviceGroup from "./UpdateDeviceGroup";
import UpdateDeviceGroupMappings from "./UpdateDeviceGroupMappings";
import ListDeviceGroupPolls from "./ListDeviceGroupPolls";

import Back from "mdi-material-ui/ArrowLeft";


const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class DeviceGroupLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

    this.deleteDeviceGroup = this.deleteDeviceGroup.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    AppDeviceGroupStore.get(this.props.match.params.deviceGroupID, resp => {
      this.setState({
        deviceGroup: resp.appDeviceGroup,
      });
    });

    SessionStore.on("change", this.setIsAdmin);

    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
    // DeviceStore.removeListener("update", this.getDevice);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationDeviceAdmin(this.props.match.params.organizationID),
    }, () => {
    });
  }

  deleteDeviceGroup() {
    if (window.confirm("Are you sure you want to delete this device type?")) {
      AppDeviceGroupStore.delete(this.props.match.params.deviceGroupID, () => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups`);
      });
    }
  }

  reloadDeviceGroup = () => {
    AppDeviceGroupStore.get(this.props.match.params.deviceGroupID, resp => {
      this.setState({
        deviceGroup: resp.appDeviceGroup,
      });
    });
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.endsWith("/edit")) {
      tab = 2;
    } else if (window.location.href.endsWith("/polls")) {
      tab = 1;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    const {admin, deviceGroup, application} = this.state;
    const {match} = this.props;

    if (application === undefined || deviceGroup === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <DeviceAdmin organizationID={match.params.organizationID}>
              <TitleBarButton
                label="Groups List"
                icon={<Back/>}
                color="primary"
                to={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups`}
              />
              <TitleBarButton
                label="Delete Device Type"
                icon={<Delete Device Type/>}
                color="secondary"
                onClick={this.deleteDeviceGroup}
              />
            </DeviceAdmin>
          }
        >
          <TitleBarTitle title={`Device Type (${deviceGroup.name})`} />
        </TitleBar>
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab value={0} label="Items" component={Link} to={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups/${match.params.deviceGroupID}`} />
            <Tab value={1} label="Polls" component={Link} to={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups/${match.params.deviceGroupID}/polls`} />
            {admin && (
              <Tab value={2} label="Details" component={Link} to={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups/${match.params.deviceGroupID}/edit`} />
            )}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Route exact path={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups/${match.params.deviceGroupID}/polls`} render={props => <ListDeviceGroupPolls deviceGroup={deviceGroup} admin={admin} {...props} match={match}/>} />
          {admin && (
            <Route exact path={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups/${match.params.deviceGroupID}/edit`} render={props => <UpdateDeviceGroup reloadRequestCallback={this.reloadDeviceGroup} deviceGroup={deviceGroup} application={application} admin={admin} {...props} />} />
          )}
          <Route exact path={`/organizations/${match.params.organizationID}/applications/${match.params.applicationID}/device-groups/${match.params.deviceGroupID}`} render={props => <UpdateDeviceGroupMappings deviceGroup={deviceGroup} application={application} disabled={!admin} {...props} />} />
        </Grid>
      </Grid>
    );
  }
}

DeviceGroupLayout.propTypes = {
  classes: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(DeviceGroupLayout));
