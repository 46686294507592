import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class AircomUserStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/aircomUser.swagger.json", sessionStore.getClientOpts());
  }

  create(user, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AircomUserService.AircomUserService_Create({
        body: {
          aircomUser: user,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "user has been " + action,
      },
    });
  }
}

const aircomUserStore = new AircomUserStore();
export default aircomUserStore;
