import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { CSVLink } from 'react-csv';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datetime from 'react-datetime';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";

import RefreshIcon from '@material-ui/icons/Refresh';
import Button from "@material-ui/core/Button";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import SettingsIcon from "@material-ui/icons/Settings";

import ApplicationStore from "../../stores/ApplicationStore";
// import DeviceStore from "../../stores/DeviceStore";
import AppDeviceStore from "../../stores/AppDeviceStore";
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";
import AppItemStore from "../../stores/AppItemStore";
import UpPacketStore from "../../stores/UpPacketStore";
import UpPacketsLog from "../../components/UpPacketsLog";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { styles } from '../../styles/aircom-styles';

class ApplicationUplinks extends Component {
  constructor(props) {
    super(props);

    const timeLoText = moment().subtract(1, 'day').startOf('day').format();
    const timeHiText = moment().add(1,'day').startOf('day').format();

    this.state = {
      // selectedDevices: [""],
      // selectedFields: [""],
      devices: null,
      deviceFilter: "",
      deviceGroups: null,
      deviceGroupFilter: 0,
      appItems: null,
      timeLoText,
      timeHiText,
      timeLoInvalid: false,
      timeHiInvalid: false,
      csvData: [],
      cacheCounter: 0,
      exportingData: false,
    };

    this.csvLink = React.createRef();
    this.downloadData = this.downloadData.bind(this);
    this.handleChangeDeviceGroup = this.handleChangeDeviceGroup.bind(this);
    this.handleChangeDevice = this.handleChangeDevice.bind(this);
    this.handleChangeTimeLo = this.handleChangeTimeLo.bind(this);
    this.handleChangeTimeHi = this.handleChangeTimeHi.bind(this);
    this.forceUpdate = this.forceUpdate.bind(this);
  }

  componentDidMount() {
    const {applicationID} = this.props.match.params;
    ApplicationStore.get(applicationID, appResp => {
      AppDeviceGroupStore.list(applicationID, 999, 0, devGroupResp => {
        //NOTE: appDeviceGroupID: 0 means no group filter
        AppDeviceStore.list(applicationID, 0, 9999, 0, deviceResp => {
          const devices = deviceResp.result;
          this.setState({
            application: appResp.application,
            deviceGroups: devGroupResp.result,
            deviceGroupFilter: 0,
            devices,
            deviceFilter: "",
          });
        });
        /*DeviceStore.list({applicationID, limit: 999, offset: 0}, deviceResp => {
          const devices = deviceResp.result;
          this.setState({
            application: appResp,
            deviceGroups: devGroupResp.result,
            deviceGroupFilter: 0,
            devices,
            deviceFilter: "",
          });
        });*/
      });
    });

    AppItemStore.list(applicationID, 100, 0, (resp) => {
      this.setState({appItems: resp.result});
    })
  }

  // componentWillUnmount() {
  //   SessionStore.removeListener("change", this.setIsAdmin);
  // }

  handleChangeDeviceGroup(event) {
    const deviceGroupFilter = event.target.value === "all" ? 0 : Number(event.target.value);
    const applicationID = this.state.application.id;
    AppDeviceStore.list(applicationID, deviceGroupFilter, 9999, 0, deviceResp => {
      const devices = deviceResp.result;
      this.setState({
        devices,
        deviceGroupFilter,
        deviceFilter: "",
      });
    });
  }

  handleChangeDevice(event) {
    // const deviceFilter = event.target.value ? event.target.value : null;
    let deviceFilter = event.target.value === "all" ? "" : event.target.value;
    this.setState({deviceFilter});
  }

  handleChangeTimeLo(newTimeLo) {
    if (typeof newTimeLo === 'string' || newTimeLo instanceof String) {
      this.setState({ timeLoInvalid: true });
      return;
    }

    // We know timeLo is valid moment
    const timeLoInvalid = false;
    let timeLo = moment(newTimeLo);
    let timeHi = moment(this.state.timeHiText);
    if (timeLo.diff(timeHi) > 0) {
      timeHi = timeLo.add(1, 'day');
    }
    // const {deviceFilter} = this.state;
    this.setState({ timeLoText: timeLo.format(), timeHiText: timeHi.format(), timeLoInvalid });
    // this.refreshStateItems({ deviceFilter, timeLo, timeHi, timeLoInvalid });
    // this.props.fetchQueryData({ deviceNames: selectedDevices, timeLo: timeLo.valueOf(), timeHi: timeHi.valueOf()});
  }

  handleChangeTimeHi(newTimeHi) {
    if (typeof newTimeHi === 'string' || newTimeHi instanceof String) {
      this.setState({ timeHiInvalid: true });
      return;
    }

    const timeHiInvalid = false;
    let timeHi = moment(newTimeHi);
    let timeLo = moment(this.state.timeLoText);
    if (timeLo.diff(timeHi) > 0) {
      timeLo = timeHi.subtract(1, 'day');
    }
    // const {deviceFilter} = this.state;    
    this.setState({ timeLoText: timeLo.format(), timeHiText: timeHi.format(), timeHiInvalid });
    // this.refreshStateItems({ deviceFilter, timeLo, timeHi,timeHiInvalid});
    // this.props.fetchQueryData({ deviceNames: selectedDevices, timeLo: timeLo.valueOf(), timeHi: timeHi.valueOf()});
  }

  forceUpdate() {
    // Passed as property to uplink packets to force refresh
    const {cacheCounter} = this.state;
    this.setState({cacheCounter: cacheCounter + 1 });
  }

  downloadData() {
    // See https://www.cluemediator.com/loading-data-asynchronously-and-download-csv-using-react-csv
    const CSV_MAX_ROWS = 99999;

    let {applicationID, timeLoText, timeHiText, deviceFilter, deviceGroupFilter} = this.state;
    const devEUI = deviceFilter ? deviceFilter.devEui : "";

    this.setState({exportingData: true}, () => {
      const csvFileName = 'Data_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
      UpPacketStore.list(CSV_MAX_ROWS, 0, applicationID, deviceGroupFilter, timeLoText, timeHiText, devEUI, (resp) => {
        const totalCount = resp.totalCount || 0;
        if (totalCount < 1) {
          // Show message to user?
          this.setState({exportingData: false});
          return;
        }

        // Each row has values array, add value as {appItem1: value}
        const csvData = resp.result.map((row) => {
          const valueKeys = Object.fromEntries(
            row.values.map((value) => {
              const appItemId = value.appItemId;
              return [
                'appItemId' + appItemId.toString(),
                value.value,
              ];
            }),
          );
          // CARE: dates will be converted from utc to local time (e.g. may add hour for BST)
          row.receivedAt = moment(row.receivedAt).format('YYYY-MM-DD HH:mm:ss');
          row.decodedAt = moment(row.decodedAt).format('YYYY-MM-DD HH:mm:ss');
          row.values = valueKeys;
          return row;
        });
  
        let csvHeaders = [
          { label: 'ID', key: 'id'},
          { label: 'Received At', key: 'receivedAt'},
          { label: 'Device ID', key: 'appDeviceId'},
          { label: 'Device Name', key: 'deviceName'},
          { label: 'Device EUID', key: 'devEui'},
          { label: 'Payload', key: 'payload'},
          { label: 'Decoded At', key: 'decodedAt'},
        ];
        // Add app items
        this.state.appItems.forEach((value) => {
          csvHeaders.push({ label: value.name, key: 'values.appItemId' + value.id});
        })

        this.setState({csvData, csvHeaders, csvFileName}, () => {
          // setTimeout needed or else latest data not downloaded
          setTimeout(() => {
            this.csvLink.current.link.click();
            this.setState({exportingData: false});
          });
        });
      });
    })
  }

  render() {
    if (this.state.application === undefined || !this.state.devices || !this.state.appItems) {
      return(<div></div>);
    }
    const {
      appItems,
      application,
      cacheCounter,
      csvData,
      csvHeaders,
      csvFileName,
      exportingData,
      devices,
      deviceFilter,
      deviceGroups,
      deviceGroupFilter,
      timeLoText,
      timeHiText,
      timeLoInvalid,
      timeHiInvalid
    } = this.state;

    const {classes} = this.props;
    const devEUI = deviceFilter ? deviceFilter.devEui : "";
    
    const showDevGroupFilter = Array.isArray(deviceGroups) && (deviceGroups.length > 1);
    // let filteredDevices = devices.filter((v) => (deviceGroupFilter !== -1)  || (v.groupId === deviceGroupFilter));

    let timeLoClass = classes.rdtInput;
    if (timeLoInvalid) {
      timeLoClass = classes.rdtInputError;
    }

    let timeHiClass = classes.rdtInput;
    if (timeHiInvalid) {
      timeHiClass = classes.rdtInputError;
    }
    let titleBarButtons = [];
    if (!exportingData) {
      titleBarButtons.push(
        <TitleBarButton
          key={1}
          icon={<CloudDownloadIcon />}
          label="Export Data"
          onClick={this.downloadData}
          disabled={exportingData}
      />
      )
    } else {
      titleBarButtons.push(
        <CircularProgress />
      )
    }

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={titleBarButtons}
        >
          <TitleBarTitle title={`Application Uplinks (${application.name})`} />
        </TitleBar>
        <Grid item xs={12}>
          {devices && (
            <Paper className={classes.paper}>
              <table>
                <tbody>
                  {showDevGroupFilter && (
                  <tr>
                    <td className={classes.td}>
                      <Typography>
                        Device&nbsp;Group:&nbsp;
                      </Typography>
                    </td>
                    <td className={classes.td}>
                      <Select
                        className={classes.textField}
                        value={deviceGroupFilter === 0 ? "all" : deviceGroupFilter}
                        onChange={this.handleChangeDeviceGroup}
                        // inputProps={{
                        //   name: 'device-name',
                        //   id: 'device-name',
                        // }}
                      >
                        <MenuItem key="all" value="all"><em>All</em></MenuItem>
                          {deviceGroups.map(deviceGroup => (
                            <MenuItem key={deviceGroup.id} value={deviceGroup.id}>{deviceGroup.name}</MenuItem>
                          ))}
                      </Select>
                    </td>
                  </tr>
                  )}

                  <tr>
                    <td className={classes.td}>
                      <Typography>
                        Device:&nbsp;
                      </Typography>
                    </td>
                    <td className={classes.td}>
                      <Select
                        className={classes.textField}
                        value={deviceFilter === "" ? "all" : deviceFilter}
                        // renderValue={() => (deviceFilter === "" ? "All" : deviceFilter)}
                        onChange={this.handleChangeDevice}
                        inputProps={{
                          name: 'device-name',
                          id: 'device-name',
                        }}
                      >
                        <MenuItem key="all" value="all"><em>All</em></MenuItem>
                          {devices.map(device => (
                            <MenuItem key={device.devEui} value={device}>{device.name}</MenuItem>
                          ))}
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.td}>
                      <Typography>
                        From:&nbsp;
                      </Typography>
                    </td>
                    <td className={classes.td}>
                      <Datetime 
                        value={moment(timeLoText)}
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm:ss"
                        onChange={this.handleChangeTimeLo}
                        // closeOnSelect={true}
                        className={classes.textField}
                        inputProps={{ className: timeLoClass }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.td}>
                      <Typography>
                        To:&nbsp;
                      </Typography>
                    </td>
                    <td className={classes.td}>
                      <Datetime 
                        value={moment(timeHiText)}
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm:ss"
                        onChange={this.handleChangeTimeHi}
                        // closeOnSelect={true}
                        className={classes.textField}
                        inputProps={{ className: timeHiClass }}
                      />
                    </td>
                    <td>
                    <Button
                style={{marginLeft:16}}
                variant="contained"
                endIcon={<RefreshIcon />}
                onClick={this.forceUpdate}
              >
                Refresh Now
              </Button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12}>
          <UpPacketsLog applicationID={application.id} organizationID={application.organizationID} groupId={deviceGroupFilter} deviceFilter={devEUI} timeLo={timeLoText} timeHi={timeHiText} cacheCounter={cacheCounter} />
        </Grid>
        {appItems &&
          <div style={{display: "none"}}>
          <CSVLink 
            data={csvData}
            headers={csvHeaders}
            filename={csvFileName}
            ref={this.csvLink}
            target="_blank"
          >
            Download Data
          </CSVLink>;
        </div>
        }
      </Grid>
    );
  }
}

ApplicationUplinks.propTypes = {
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,  
}

export default withStyles(styles)(withRouter(ApplicationUplinks));
