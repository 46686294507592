import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AlarmIcon from '@material-ui/icons/Alarm';
import DeviceQueueStore from "../../stores/DeviceQueueStore";
//import DeviceStore from "../../stores/DeviceStore";
import AppDeviceStore from "../../stores/AppDeviceStore";
import { Button } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import moment from "moment";

/**
 * Return number of mins since 00:00 on given date
 * Will never return zero. (Midnight = 1440)
 * @param {*} date
 */
function utcDateToMins(date) {
  if (date == null) {
    return 0; //not set
  }
  const dayStart = moment.utc( date ).startOf('day');
  let result = moment.utc(date).diff(dayStart, 'minutes');
  if (result === 0) {
    result = 1440; // Zero means undefined
  }
  return result;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class AircomOutput extends Component {
  constructor() {
    super();
    this.state = {
      selectedDevices: [],
      devices: [],
      A1Time: null,
      B1Time: null,
      A2Time: null,
      B2Time: null,
      A3Time: null,
      B3Time: null,
      A4Time: null,
      B4Time: null,
      A5Time: null,
      B5Time: null,
      A6Time: null,
      B6Time: null
    };

    this.timeChangedA1 = this.timeChangedA1.bind(this);
    this.timeChangedA2 = this.timeChangedA2.bind(this);
    this.timeChangedA3 = this.timeChangedA3.bind(this);
    this.timeChangedA4 = this.timeChangedA4.bind(this);
    this.timeChangedA5 = this.timeChangedA5.bind(this);
    this.timeChangedA6 = this.timeChangedA6.bind(this);
    this.timeChangedB1 = this.timeChangedB1.bind(this);
    this.timeChangedB2 = this.timeChangedB2.bind(this);
    this.timeChangedB3 = this.timeChangedB3.bind(this);
    this.timeChangedB4 = this.timeChangedB4.bind(this);
    this.timeChangedB5 = this.timeChangedB5.bind(this);
    this.timeChangedB6 = this.timeChangedB6.bind(this);

    this.queueSchedule = this.queueSchedule.bind(this);

    this.handleChangeDevice = this.handleChangeDevice.bind(this);
  }

  timeChangedA1(date) { this.setState({ A1Time: date }); }

  timeChangedA2(date) { this.setState({ A2Time: date }); }

  timeChangedA3(date) { this.setState({ A3Time: date }); }

  timeChangedA4(date) { this.setState({ A4Time: date }); }

  timeChangedA5(date) { this.setState({ A5Time: date }); }

  timeChangedA6(date) { this.setState({ A6Time: date }); }

  timeChangedB1(date) { this.setState({ B1Time: date }); }

  timeChangedB2(date) { this.setState({ B2Time: date }); }

  timeChangedB3(date) { this.setState({ B3Time: date }); }

  timeChangedB4(date) { this.setState({ B4Time: date }); }

  timeChangedB5(date) { this.setState({ B5Time: date }); }

  timeChangedB6(date) { this.setState({ B6Time: date }); }


  queueSchedule() {
    if (window.confirm("Do you want to queue the new schedule for the selected devices? Any schedule previously queued, but not downloaded, will be replaced by the new one.")) {
      //needs to be a base64 encoded string
      let buffer = Buffer.alloc(25);
      const { selectedDevices } = this.state;
      
      buffer.writeUInt8(1,0);
      buffer.writeUInt16BE(utcDateToMins(this.state.A1Time),1);
      buffer.writeUInt16BE(utcDateToMins(this.state.A2Time),3);
      buffer.writeUInt16BE(utcDateToMins(this.state.A3Time),5);
      buffer.writeUInt16BE(utcDateToMins(this.state.A4Time),7);
      buffer.writeUInt16BE(utcDateToMins(this.state.A5Time),9);
      buffer.writeUInt16BE(utcDateToMins(this.state.A6Time),11);
      buffer.writeUInt16BE(utcDateToMins(this.state.B1Time),13);
      buffer.writeUInt16BE(utcDateToMins(this.state.B2Time),15);
      buffer.writeUInt16BE(utcDateToMins(this.state.B3Time),17);
      buffer.writeUInt16BE(utcDateToMins(this.state.B4Time),19);
      buffer.writeUInt16BE(utcDateToMins(this.state.B5Time),21);
      buffer.writeUInt16BE(utcDateToMins(this.state.B6Time),23);

      selectedDevices.forEach((device) => {
        const queueItem = {
          devEUI: device.devEui,
          f_port: 2,
          data: buffer.toString('base64'),
        }

        DeviceQueueStore.flush(device.devEui, () => {
          DeviceQueueStore.enqueue(queueItem, () => {})
        })
      }  
      );

      //this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/downlinks`);
    }
  };

  handleChangeDevice(event) {
    this.setState({ selectedDevices: event.target.value });
  }

  componentDidMount() {
    /*DeviceStore.list({
      applicationID: this.props.match.params.applicationID,
      limit: 999,
      offset: 0,
    }, resp => {
      this.setState({
        devices: resp.result
      });
    });*/
    const {applicationID} = this.props.match.params;  //M.O. 2022-03-15 Seems to be empty!
    //NOTE: appDeviceGroupID: 0 means no group filter and outputControlType: 1 means Step
    AppDeviceStore.listByOutputType(this.props.application.id,0,1,9999,0, resp => {
      console.log(resp);
      this.setState({
        devices: resp.result
      });
    });
  }

  render() {
    const {devices, selectedDevices} = this.state;

    return(
      <Grid item xs={12}>
        <Typography variant="h6" color="inherit">
            New Schedule
        </Typography>
        <Paper style={{
          padding: 8,
          marginTop: 8,
        }}>
        <Grid container spacing={2}>
        <Grid item xs={5}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell ></TableCell>
                <TableCell >Output A</TableCell>
                <TableCell >Output B</TableCell>
              </TableRow>
            </TableHead>
            <TableBody> 
              <TableRow key="Time1">
                <TableCell  component="th" scope="row">
                  Pulse Time 1:
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                  ampm={false}
                  emptyLabel="Not Set"
                  value={this.state.A1Time} 
                  onChange={this.timeChangedA1} 
                  keyboardIcon={<AlarmIcon />}
                  />
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.B1Time} 
                    onChange={this.timeChangedB1} 
                    keyboardIcon={<AlarmIcon />}
                  />  
                </TableCell>
              </TableRow>
              <TableRow key="Time2">
                <TableCell component="th" scope="row">
                  Pulse Time 2:
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.A2Time} 
                    onChange={this.timeChangedA2} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.B2Time} 
                    onChange={this.timeChangedB2} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
              </TableRow>
              <TableRow key="Time3">
                <TableCell component="th" scope="row">
                  Pulse Time 3:
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.A3Time} 
                    onChange={this.timeChangedA3} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.B3Time} 
                    onChange={this.timeChangedB3} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
              </TableRow>
              <TableRow key="Time4">
                <TableCell  component="th" scope="row">
                  Pulse Time 4:
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.A4Time} 
                    onChange={this.timeChangedA4} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
                <TableCell >
                <KeyboardTimePicker 
                  ampm={false}
                  emptyLabel="Not Set"
                  value={this.state.B4Time} 
                  onChange={this.timeChangedB4} 
                  keyboardIcon={<AlarmIcon />}
                  />
                </TableCell>
              </TableRow>
              <TableRow key="Time5">
                <TableCell  component="th" scope="row">
                  Pulse Time 5:
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.A5Time} 
                    onChange={this.timeChangedA5} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
                <TableCell >
                <KeyboardTimePicker 
                  ampm={false}
                  emptyLabel="Not Set"
                  value={this.state.B5Time} 
                  onChange={this.timeChangedB5} 
                  keyboardIcon={<AlarmIcon />}
                  />
                </TableCell>
              </TableRow>
              <TableRow key="Time6">
                <TableCell  component="th" scope="row">
                  Pulse Time 6:
                </TableCell>
                <TableCell >
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.A6Time} 
                    onChange={this.timeChangedA6} 
                    keyboardIcon={<AlarmIcon />}
                    />
                </TableCell>
                <TableCell >
                <KeyboardTimePicker 
                  ampm={false}
                  emptyLabel="Not Set"
                  value={this.state.B6Time} 
                  onChange={this.timeChangedB6} 
                  keyboardIcon={<AlarmIcon />}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1}>
            <Typography>
              Apply to:&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={3}>
        
            <Select
              id="devices"
              multiple
              value={selectedDevices}
              onChange={this.handleChangeDevice}
              input={<Input id="select-devices" />}
              renderValue={(selected) => (
                <div style={{display: 'flex',
                  flexWrap: 'wrap',}}>
                  {selected.map((value) => (
                    <Chip key={value.devEui} label={value.name} style={{
                      margin: 2,
                    }} />
                  ))}
                </div>
              )}
              displayEmpty={true}
              MenuProps={MenuProps}
            >
              {devices.map((device) => (
                <MenuItem key={device.devEui} value={device}>
                  <Checkbox color="primary" checked={selectedDevices.indexOf(device) > -1} />
                  <ListItemText primary={device.name} />
                </MenuItem>
              ))}
            </Select>
       
          </Grid>
          <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon></SendIcon>}
            onClick={() => { this.queueSchedule(); }}
          >
            Send
          </Button>
          </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

AircomOutput.propTypes = {
  match: PropTypes.any.isRequired,
}

export default AircomOutput;
