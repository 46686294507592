import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
//import DataTable from "../components/DataTable";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from "./Paper";

import UpPacketStore from "../stores/UpPacketStore";

import {formatReading} from '../styles/formatters';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { styles } from '../styles/aircom-styles';

export class DeviceReadingsTable extends Component {
  constructor() {
    super();

    this.state = {
      fetchingData: false,
      data: null,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps){
    if ((prevProps === this.props) || this.state.fetchingData) {
      return;
    }

    //only do this if any of the filter has changed, otherwise this is called recursively (timeHi only means that the refresh time has occurred)
    if(
      (prevProps.deviceFilter !== this.props.deviceFilter) ||
      (prevProps.timeHi !== this.props.timeHi) 
    ){
      this.fetchData(prevProps.deviceFilter !== this.props.deviceFilter); // Clear so previous data no longer showing
    }
  }

  fetchData(clearDataFirst) {
    // If deviceFilter blank, no need to fetch data
    const {deviceFilter} = this.props;

    if (!deviceFilter) {
      this.setState({fetchingData: false, data: null});
      return;
    }
    
    const data = (clearDataFirst === true) ? null : this.state.data;
    this.setState({fetchingData: true, data}, () => {
      UpPacketStore.getLatestReadings(this.props.applicationID,"",this.props.deviceFilter,resp => {
        const data = {
          result: resp.items,
        }
        this.setState({fetchingData: false, data});
      });
    });
  }

  render() {
    const {deviceFilter, filterName, classes} = this.props;
    const {data} = this.state;
    if(!deviceFilter) {
      return null;
    }
    
    return(
      <Grid item xs={12}>
      <Typography variant="h5" color="inherit">
        Latest Readings ({filterName ? filterName : 'All Devices'})
        {this.state.fetchingData ? "..." : ""}
      </Typography>
      <Paper>

      {(data === null) && (
            <Grid item>
              <Typography>
                Loading data...
              </Typography>
            </Grid>
      )}

        <Table className={classes.table}>
          <TableHead>
            
            {data && data.result[0] && (
                <TableRow>
                <TableCell>Reading</TableCell>
                { data.result[0].deviceReadings.map((value) => {
                  return (
                    <TableCell>{value.devEui}</TableCell>
                  )
                })}
                </TableRow>
            )}
            
          </TableHead>
          <TableBody>
          {data && (
            data.result.map((value) => {
              return(
                <TableRow
                  key={value.id}
                  hover
                >
                  <TableCell>{value.appItemName}</TableCell>
                  {value.deviceReadings.map((reading) => {
                    return(
                    <TableCell>{(reading.receivedAt ? formatReading(reading.value, value.format) : "N/A")}</TableCell>
                    )
                  })}
                </TableRow>
              )
            }
            ))}
          </TableBody>
        </Table>
      </Paper>
      </Grid>
    );
  }
}

DeviceReadingsTable.propTypes = {
  applicationID: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  deviceFilter: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  timeHi: PropTypes.string.isRequired,
};

export default withStyles(styles)(DeviceReadingsTable);
