import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';

import Plus from "mdi-material-ui/Plus";

import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import DeviceAdmin from "../../components/DeviceAdmin";
import AppDeviceGroupStore from "../../stores/AppDeviceGroupStore";
import theme from "../../theme";


const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
};


class ListDeviceGroups extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    AppDeviceGroupStore.list(this.props.match.params.applicationID, limit, offset, callbackFunc);
  }

  getRow(obj) {
    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.description}</TableCell>
        <TableCell>{obj.deviceCount}</TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Group Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Device Count</TableCell>
              </TableRow>
            }

            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
        <DeviceAdmin organizationID={this.props.match.params.organizationID}>
          <Grid item xs={12} className={this.props.classes.buttons}>
            <Button variant="outlined" className={this.props.classes.button} component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/create`}>
              <Plus className={this.props.classes.icon} />
              Create
            </Button>
          </Grid>
        </DeviceAdmin>
      </Grid>
    );
  }
}

ListDeviceGroups.propTypes = {
  // application: PropTypes.object.isRequired,
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(ListDeviceGroups);
