import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
// import Input from "@material-ui/core/Input";
// import InputAdornment from "@material-ui/core/InputAdornment";
import blue from "@material-ui/core/colors/blue";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import AutocompleteSelect from "./AutocompleteSelect";

import MenuIcon from "mdi-material-ui/Menu";
import Backburger from "mdi-material-ui/Backburger";
import AccountCircle from "mdi-material-ui/AccountCircle";
// import Magnify from "mdi-material-ui/Magnify";
// import HelpCicle from "mdi-material-ui/HelpCircle";

import InternalStore from "../stores/InternalStore";
import SessionStore from "../stores/SessionStore";
import theme from "../theme";
import localStore, { VIEW_ORGADMIN, VIEW_SYSADMIN, VIEW_USER } from "../stores/LocalStore";
import OrganizationStore from "../stores/OrganizationStore";
import ApplicationStore from "../stores/ApplicationStore";
import AircomSystemStore from "../stores/AircomSystemStore";

/**
 * Only set view mode from TopNav component (to keep control in one place)
 */

const styles = {
  appBar: {
    colorSecondary: "#002060",
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  hidden: {
    display: "none",
  },
  flex: {
    flex: 1,
  },
  label: {
    marginLeft: 8,
  },
  logo: {
    height: 32,
  },
  search: {
    marginRight: 3 * theme.spacing(1),
    color: theme.palette.common.white,
    background: blue[400],
    width: 450,
    padding: 5,
    borderRadius: 3,
  },
  select: {
    background: blue[400],
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 300,
    // flex: 1,
  },
  chip: {
    background: blue[600],
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    "&:hover": {
      background: blue[400],
    },
    "&:active": {
      background: blue[400],
    },
  },
  iconButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
};


class TopNav extends Component {
  constructor() {
    super();

    this.state = {
      menuAnchor: null,
      search: "",
      oidcEnabled: false,
      organization: null,
      view: VIEW_USER,
      cacheCounter: 0,
      showOrgDropdown: true,
    };

    this.setOrganizationFromLocation = this.setOrganizationFromLocation.bind(this);
    this.onOrgSelectChange = this.onOrgSelectChange.bind(this);
    this.getOrganizationOption = this.getOrganizationOption.bind(this);
    this.getOrganizationOptions = this.getOrganizationOptions.bind(this);
    this.refreshShowOrgDropdown = this.refreshShowOrgDropdown.bind(this);
    this.reloadOrgDropdown = this.reloadOrgDropdown.bind(this);
    this.onOrganizationDeleted = this.onOrganizationDeleted.bind(this);
    this.setView = this.setView.bind(this);
  }

  componentDidMount() {
    InternalStore.settings(resp => {
      this.setState({
        oidcEnabled: resp.openidConnect.enabled,
        logoutURL: resp.openidConnect.logoutURL,
      });
    });
    // Listeners only needed to reload organization dropdown
    OrganizationStore.on("create", this.reloadOrgDropdown);
    OrganizationStore.on("change", this.reloadOrgDropdown);
    OrganizationStore.on("delete", this.onOrganizationDeleted);
    AircomSystemStore.on("organization.deleted", this.onOrganizationDeleted);
    // SessionStore.on("organization.change", this.onSessionOrgChange);
    this.setState({view: localStore.getView()});
    this.setOrganizationFromLocation();
  }

  componentWillUnmount() {
    OrganizationStore.removeListener("create", this.reloadOrgDropdown);
    OrganizationStore.removeListener("change", this.reloadOrgDropdown);
    OrganizationStore.removeListener("delete", this.reloadOrgDropdown);
    AircomSystemStore.removeListener("org.deleted", this.onOrganizationDeleted)
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }
    // New route either from link or user typed new url into browser address bar
    this.setOrganizationFromLocation();
  }

  setOrganizationFromLocation() {
    const organizationRe = /\/organizations\/(\d+)/g;
    const match = organizationRe.exec(this.props.history.location.pathname);

    let {organization, view} = this.state;
    if (match !== null && (organization === null || organization.id !== match[1])) {
      // Get organization (don't push new route unless org invalid)
      organization = null; // In case no organization fetched
      view = VIEW_USER;
      const newOrgID = match[1];
      // Don't use OrganizationStore.get as it will not execute callback if no org fetched
      // User may have typed invalid org/app ids into address bar
      OrganizationStore.list("", 99, 0, (resp) => {
        let organizations = [];
        if (resp.result.length > 0) {
          organizations = resp.result.filter(org => org.id === newOrgID);
        }
        // Organization exists
        if (organizations.length === 1) {
          organization = organizations[0];
        }
        SessionStore.setOrganizationID(organization ? organization.id : "");        
        if (
          (view === VIEW_SYSADMIN && !SessionStore.isAdmin()) ||
          (view === VIEW_ORGADMIN && !SessionStore.isAdmin() && !SessionStore.isOrganizationAdmin(newOrgID))
        )
        {
          view = VIEW_USER;
        }
        this.setState({organization, view}, () => {
          if (organization === null) {
            localStore.setApplicationID("");
            this.props.history.push("/"); // Let Organization redirect direct next step
          }
        });
      });
    }
  }

  onMenuOpen = (e) => {
    this.setState({
      menuAnchor: e.currentTarget,
    });
  }

  onMenuClose = () => {
    this.setState({
      menuAnchor: null,
    });
  }

  onLogout = () => {
    if (this.state.oidcEnabled === true) {
      if (this.state.logoutURL !== "") {
        SessionStore.logout(false, () => {
          window.location.assign(this.state.logoutURL);
        });
      } else {
        SessionStore.logout(true, () => {
          this.props.history.push("/login");
        });
      }
    } else {
      SessionStore.logout(true, () => {
        this.props.history.push("/login");
      });
    }
  }

  setView(view) {

    this.setState({view});
    localStore.setView(view); // Will trigger this.onViewChanged event
    // Following line to emit organization.change event to prompt refresh of current page
    SessionStore.emitChangeEvent();
    this.onMenuClose();
  }

  onOrganizationDeleted(id) {
    // Has current organization been deleted?
    const {organization} = this.state;
    if (organization !== null && organization.id === id) {
      SessionStore.setOrganizationID("");
      localStore.setApplicationID("");
      const {cacheCounter} = this.state;
      this.setState({organization: null, cacheCounter:cacheCounter + 1}, () => {
        this.props.history.push("/"); // Organization redirect will sort out next stop
      })
    }
  }

  reloadOrgDropdown() {
    // Ensure dropdown refreshed
    this.setState({cacheCounter: this.state.cacheCounter + 1});
  }

  onOrgSelectChange(e) {
    let {organization, view} = this.state;
    const newOrgID = e.target.value;

    if (!organization || (organization.id !== newOrgID)) {
      // Get organization
      organization = null; // In case no organization fetched
      // As event triggered by dropdown, the org should exist
      OrganizationStore.get(newOrgID, resp => {
        organization = resp.organization;

        // Get default application
        ApplicationStore.list('', newOrgID, 1, 0, resp => {
          SessionStore.setOrganizationID(newOrgID)
          if (
            (view === VIEW_SYSADMIN && !SessionStore.isAdmin()) ||
            (view === VIEW_ORGADMIN && !SessionStore.isAdmin() && !SessionStore.isOrganizationAdmin(newOrgID))
          )
          {
            view = VIEW_USER;
          }
          this.setState({organization, view}); // Saves requery on component update
          if (resp.totalCount > 0) {
            this.props.history.push(`/organizations/${newOrgID}/applications/${resp.result[0].id}/dashboard`);
            // SideNav should pick up change of application
          } else {
            this.props.history.push(`/organizations/${newOrgID}`);
          }
        });
      });
    }
  }

  getOrganizationOption(id, callbackFunc) {
    OrganizationStore.get(id, resp => {
      callbackFunc({label: resp.organization.name, value: resp.organization.id});
    });
  }

  getOrganizationOptions(search, callbackFunc) {
    OrganizationStore.list(search, 99, 0, resp => {
      const options = resp.result.map((o) => {return {label: o.name, value: o.id}});
      callbackFunc(options, resp.totalCount);
    });
  }

  handleDrawerToggle = () => {
    this.props.setDrawerOpen(!this.props.drawerOpen);
  }

  onSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.props.history.push(`/search?search=${encodeURIComponent(this.state.search)}`);
  }

  refreshShowOrgDropdown() {
    const showOrgDropdown = SessionStore.organizations.length > 1;
    this.setState({showOrgDropdown});
  }

  render() {
    const {organization, view} = this.state;
    let organizationID = "";
    let organizationName = "";
    if (organization !== null) {
      organizationID = organization.id;
      organizationName = organization.name;
    }

    let drawerIcon;
    if (!this.props.drawerOpen) {
      drawerIcon = <MenuIcon />;
    } else {
      drawerIcon = <Backburger />;
    }

    const open = Boolean(this.state.menuAnchor);
    const orgAdminView = view === VIEW_ORGADMIN;
    const systemAdminView = view === VIEW_SYSADMIN;
    // Call SessionStore.isAdmin etc with false parameter (it won't check current view mode)
    const showOrgAdminView = SessionStore.isAdmin(false) || SessionStore.isOrganizationAdmin(organization !== null ? organization.id : 0, false);
    const showSysAdminView = SessionStore.isAdmin(false);
    const showUserView = showOrgAdminView || showSysAdminView; // No need to show if no other options available

    return(
      <AppBar className={this.props.classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={this.handleDrawerToggle}
            className={this.props.classes.menuButton}
          >
            {drawerIcon}
          </IconButton>

          <div>
            <img src="/logo/logo.png" className={this.props.classes.logo} alt="Aircom" />
          </div>

          {/* <div> */}
            <Typography className={this.props.classes.label}>
              &nbsp;Organization:&nbsp;
            </Typography>
            {systemAdminView && (
              <AutocompleteSelect
                id="organizationID"
                margin="none"
                value={organizationID}
                onChange={this.onOrgSelectChange}
                getOption={this.getOrganizationOption}
                getOptions={this.getOrganizationOptions}
                className={this.props.classes.select}
                triggerReload={this.state.cacheCounter}
              />
            )}
            {!systemAdminView && (
              <Typography className={this.props.classes.label}>
                {organizationName}&nbsp;
              </Typography>
            )}
          <div className={this.props.classes.flex}></div>

          {/* <form onSubmit={this.onSearchSubmit}>
            <Input
              placeholder="Search organization, application, gateway or device"
              className={this.props.classes.search}
              disableUnderline={true}
              value={this.state.search || ""}
              onChange={this.onSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <Magnify />
                </InputAdornment>
              }
            />
          </form>

          <a href="https://www.chirpstack.io/application-server/" target="chirpstack-doc">
            <IconButton className={this.props.classes.iconButton}>
              <HelpCicle />
            </IconButton>
          </a> */}

          {orgAdminView && (
            <Typography>
              Admin View&nbsp;&nbsp;
            </Typography>
          )}
          
          {systemAdminView && (
            <Typography>
              System View&nbsp;&nbsp;
            </Typography>
          )}

          <Chip
            avatar={
              <Avatar>
                <AccountCircle />
              </Avatar>
            }
            label={this.props.user.email}
            onClick={this.onMenuOpen}
            color="primary"
            classes={{
              root: this.props.classes.chip,
            }}
          />
          <Menu
            id="menu-appbar"
            anchorEl={this.state.menuAnchor}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={this.onMenuClose}
          >
            {showUserView && (
              <MenuItem onClick={() => this.setView(VIEW_USER)} selected={view === VIEW_USER}>User View</MenuItem>
            )}
            {showOrgAdminView && (
              <MenuItem onClick={() => this.setView(VIEW_ORGADMIN)} selected={view === VIEW_ORGADMIN}>Admin View</MenuItem>
            )}
            {showSysAdminView && (
              <MenuItem onClick={() => this.setView(VIEW_SYSADMIN)} selected={view === VIEW_SYSADMIN}>System View</MenuItem>
            )}
            {showUserView && (
              <Divider />
            )}
            
            {!this.state.oidcEnabled && <MenuItem component={Link} to={`/users/${this.props.user.id}/password`}>Change password</MenuItem>}
            <MenuItem onClick={this.onLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

TopNav.propTypes = {
  classes: PropTypes.any.isRequired,
  drawerOpen: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  setDrawerOpen: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(TopNav));
