import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormComponent from "../../classes/FormComponent";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import Form from "../../components/Form";
import AppDeviceGroupPollStore from "../../stores/AppDeviceGroupPollStore";

const styles = {
  formLabel: {
    fontSize: 12,
  },
};


class DeviceGroupPollForm extends FormComponent {
  constructor() {
    super();
    this.getPollDataTypeOption = this.getPollDataTypeOption.bind(this);
    this.getPollDataTypeOptions = this.getPollDataTypeOptions.bind(this);

    this.state = {};
  }

  getPollDataTypeOption(id, callbackFunc) {
    AppDeviceGroupPollStore.getPollDataType(id, resp => {
      callbackFunc({label: resp.pollDataType.name, value: resp.pollDataType.id});
    });
  }

  getPollDataTypeOptions(search, callbackFunc) {
    AppDeviceGroupPollStore.listPollDataType(resp => {
      const options = resp.result.map((dt, i) => {return {label: dt.name, value: dt.id}});
      callbackFunc(options);
    });
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }
    
    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <div>
          <TextField
            id="pollNumber"
            label="Poll number"
            type="number"
            margin="normal"
            value={this.state.object.pollNumber || 0}
            onChange={this.onChange}
            helperText="Poll number (1-10)"
            required
            fullWidth
            disabled={this.props.disabled}
          />
          <TextField
            id="pollRegCount"
            label="Items count"
            type="number"
            margin="normal"
            value={this.state.object.pollRegCount || 0}
            onChange={this.onChange}
            helperText={this.state.object.pollDataTypeID == 1 ? "Discrete Inputs / Coils count" : "Register count (16-bit registers)"}
            required
            fullWidth
            disabled={this.props.disabled}
          />
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Data type</FormLabel>
            <AutocompleteSelect
              id="pollDataTypeID"
              label="Data type"
              value={this.state.object.pollDataTypeID}
              onChange={this.onChange}
              getOption={this.getPollDataTypeOption}
              getOptions={this.getPollDataTypeOptions}
              helperText="Data type"
              required
              fullWidth
              disabled={this.props.disabled}
            />
          </FormControl>
        </div>
      </Form>
    );
  }
}

export default withStyles(styles)(DeviceGroupPollForm);
