import React, { Component } from "react";
import PropTypes from "prop-types";

// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DataTable from "./DataTable";
import Grid from '@material-ui/core/Grid';

import UpPacketStore from "../stores/UpPacketStore";

import WarningIcon from '@material-ui/icons/Warning';
// import FunctionsIcon from '@material-ui/icons/Functions';
import Button from '@material-ui/core/Button';

import {formatReading} from '../styles/formatters';
import moment from 'moment';
import SessionStore from "../stores/SessionStore";

export default class UpPacketsLog extends Component {
  constructor() {
    super();
    this.state = {
      fetchingData: false,
      cacheCounter: 0,
    };
    this.getRow = this.getRow.bind(this);
    this.getPage = this.getPage.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   if ((prevProps === this.props) || this.state.fetchingData) {
  //     return;
  //   }
  // if ((prevProps.cacheCounter !== this.props.cacheCounter) && !this.state.fetchingData) {
  //   // Parent component increased cacheCounter to force refresh and display ellipsis
  //   this.setState({fetchingData: true}); // Will be cleared when page fetched      
  // }
  // }

  getPage(limit, offset, callbackFunc) {
    /* Need to pass 0 or empty string when parameters are not set - See default for not-set below*/ 
    // var timeLo = ""; //no start time
    // var timeHi = ""; //no start time
    // var applicationId = 0; //no application id
    // var groupId = 0; //no group id
    // var devEui = ""; //no devEui
    let {applicationID, groupId, timeLo, timeHi, deviceFilter} = this.props;

    //Some testing values
    // timeLo = moment().subtract(2, 'day').startOf('day').format();
    // timeHi = moment().add(1, 'day').startOf('day').format();
    // applicationId = 1;
    // groupId = 1;
    // devEui = "c0ee400001013822"; 
    UpPacketStore.list(limit, offset, applicationID, groupId, timeLo, timeHi, deviceFilter, (result) => {
      callbackFunc(result);
      // if (this.state.fetchingData) {
      //   //   // May cause re-render, leading to one surplus call to getPage
      //   //   // Only call when absolutely necessary
      //   this.setState({fetchingData: false});
      // }
    });
  }

  getRow(obj) {
    const valueCount = obj.values.length;
    const {itemsSkipped} = obj;
    const isAdmin = (SessionStore.isAdmin() === true) || (SessionStore.isOrganizationAdmin(this.props.organizationID) === true);

    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell>{moment(obj.receivedAt).format("lll")}</TableCell>
        <TableCell>{obj.devEui}</TableCell>
        <TableCell>{obj.deviceName}</TableCell>

        <TableCell>
          <details>
            <summary>
              {valueCount} {valueCount === 1 ? 'Value' : 'Values'}{itemsSkipped ? ` (Errors ${itemsSkipped})` : null}
              {(itemsSkipped > 0) && (
                <WarningIcon style={{color:'red' }}/>
              )}
            </summary>
            <ul className="reading-list">
            {obj.values.map((value) => {
              return <li key={value.id}>{value.name}: {formatReading(value.value, value.format)}</li>
            })}
            {(itemsSkipped > 0) && (
              <li>Items skipped: {itemsSkipped}</li>
            )}
            </ul>
            {(isAdmin === true) && (
              <Button style={{color:'red' }} onClick={() => {this.decodePacket(obj.id)}} >
                Decode&nbsp;now
              </Button>
            )}
          </details>
        </TableCell>
        <TableCell>
          <details>
            <summary>
              {obj.decodedAt ? moment(obj.decodedAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
            </summary>
            Payload:&nbsp;{obj.payload}
          </details>
        </TableCell>
      </TableRow>
    );
  }

  decodePacket = (packetID) => {
    // Prompt user...
    UpPacketStore.decodePacket(packetID, () => {
      this.setState({cacheCounter: this.state.cacheCounter + 1}); // To force re-render and requery
      // May need to use x-grid rather than current DataTable component
    })
  };

  render() {
    // const isAdmin = SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.organizationID)
    return(
      <Grid item xs={12}>
      <Typography variant="h5" color="inherit">
        Uplink Packets
        {this.state.fetchingData ? "..." : ""}
      </Typography>

          <DataTable
            header={
              <TableRow>
                <TableCell>Received At</TableCell>
                <TableCell>Device EUI</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Values</TableCell>
                <TableCell>Decoded At</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
      </Grid>
    );
  }
}

UpPacketsLog.propTypes = {
  applicationID: PropTypes.any.isRequired,
  deviceFilter: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  organizationID: PropTypes.number.isRequired,
  timeLo: PropTypes.string.isRequired,
  timeHi: PropTypes.string.isRequired,
  // cacheCounter: PropTypes.any,
}

// UpPacketsLog.defaultProps = {
//   cacheCounter: 0,
// }
