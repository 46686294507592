import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  formLabel: {
    fontSize: 12,
  },
};

class DeviceGroupForm extends FormComponent {
  constructor() {
    super();

    this.state = {};
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e) {
    let object = this.state.object;
    object["outputControlType"] = e.target.value;
    this.setState({
      object: object,
    });
  }
  

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <div>
          <TextField
            id="name"
            label="Device type name"
            helperText="The name may only contain letters, numbers and dashes."
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            fullWidth
            required
          />
          <TextField
            id="abbreviation"
            label="Abbreviation"
            helperText="The abbreviation may only contain letters, numbers and dashes."
            margin="normal"
            value={this.state.object.abbreviation || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            fullWidth
            required
          />
          <TextField
            id="description"
            label="Device type description"
            margin="normal"
            value={this.state.object.description || ""}
            onChange={this.onChange}
            fullWidth
            required
          />
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Output Control Type</FormLabel>
                <Select
                  id="outputControlType"
                  value={this.state.object.outputControlType}
                  onChange={this.handleSelect}
                >
                  <MenuItem key="1" value={0}>None</MenuItem>
                  <MenuItem key="2" value={1}>Step</MenuItem>
                  <MenuItem key="3" value={2}>PV Control</MenuItem>
                </Select>
          </FormControl>
          
          </div>
      </Form>
    );
  }
}

export default withStyles(styles)(DeviceGroupForm);
