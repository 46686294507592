import React from "react";

import { withStyles } from "@material-ui/core/styles";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import Typography from '@material-ui/core/Typography';

const styles = {
  formLabel: {
    fontSize: 12,
  },
};


class DisableAppDeviceForm extends FormComponent {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.state.object.disabledAt ? "Enable Device" : "Disable Device"}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <div>
            <Typography variant="h6" color={this.state.object.disabledAt ? "secondary" : "primary"}>
                {this.state.object.disabledAt ? "This device has been disabled" : "This device is enabled"}
            </Typography>
        </div>
      </Form>
    );
  }
}

export default withStyles(styles)(DisableAppDeviceForm);
