import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class UpPacketStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/upPacket.swagger.json", sessionStore.getClientOpts());
  }

  list(limit, offset, applicationId, appDeviceGroupId, from, to, devEui, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.UpPacketService.UpPacketService_List({
        limit: limit,
        offset: offset,
        applicationId: applicationId,
        appDeviceGroupId: appDeviceGroupId,
        from: from,
        to: to,
        devEui: devEui,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getLatestReadings(applicationId, appDeviceGroupId, devEui, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.UpPacketService.UpPacketService_GetLatestReadings({
        applicationId: applicationId,
        appDeviceGroupId: appDeviceGroupId,
        devEui: devEui,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getReadingsInRange(applicationId, appItemId, devEui, from, to, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.UpPacketService.UpPacketService_GetReadingsInRange({
        applicationId: applicationId, 
        appItemId: appItemId,
        devEui: devEui,
        from: from,
        to: to,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  decodePacket(packetId, callbackFunc){
    this.swagger.then(client => {
      client.apis.UpPacketService.UpPacketService_Decode({
        "id": packetId,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("decoded");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  decodeAllPackets(callbackFunc){
    this.swagger.then(client => {
      client.apis.UpPacketService.UpPacketService_DecodeAll({
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("decoded");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deletePacket(packetId, callbackFunc){
    this.swagger.then(client => {
      client.apis.UpPacketService.UpPacketService_Delete({
        "id": packetId,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "packet has been " + action,
      },
    });
  }
}

const upPacketStore = new UpPacketStore();
export default upPacketStore;
