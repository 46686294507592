import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
// import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import HexTextField from '../../components/HexTextField';
import { decodePayload } from "../../helpers/packetHelper";
import AppItemStore from "../../stores/AppItemStore";
import { formatReading } from "../../styles/formatters";
// import { styles } from '../../styles/aircom-styles';

const styles = {
};

class PacketDecoderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payloadItems: null,
      packetPayload: "",
      decodedPacket: null,
    };

    this.onChangePacketPayload = this.onChangePacketPayload.bind(this);
  }

  componentDidMount() {
    // Load packet items
    AppItemStore.listPayloadItem(resp => {
      this.setState({
        payloadItems: resp.result,
      });
    });
  }

  onChangePacketPayload(event) {
    const packetPayload = event.target.value;
    const decodedPacket = decodePayload(packetPayload);
    this.setState({packetPayload, decodedPacket});
  }

  render() {
    const {packetPayload, payloadItems} = this.state;
    const pkt = this.state.decodedPacket;
    const {classes} = this.props;

    if (payloadItems === null) {
      return null;
    }

    let polledRows = [];
    if (pkt) {
      for (let i = 1; i < 11; i++) {
        if (pkt[`modbusPoll${i}`] !== undefined) {
          polledRows.push(
            <TableRow>
              <TableCell>{`Poll ${i}`}</TableCell>
              <TableCell>Yes</TableCell>
            </TableRow>
          )
        }
      }
    }

    return(
      <Paper className={classes.paper}>
        <HexTextField
          id="name"
          label="Packet payload: "
          helperText="Enter the packet payload (hex format)"
          margin="normal"
          value={packetPayload}
          onChange={this.onChangePacketPayload}
          fullWidth
          required
        />
        {/* {JSON.stringify(pkt)} */}
        {(pkt && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Reading</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {}
              <TableRow>
                <TableCell>Payload length (bytes)</TableCell>
                <TableCell>{packetPayload.length / 2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payload is valid</TableCell>
                <TableCell>{pkt.validPacket ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Digital-only packet</TableCell>
                <TableCell>{pkt.digitalOnly === 1 ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Header length (bytes)</TableCell>
                <TableCell>{pkt.headerLength}</TableCell>
              </TableRow>
              {pkt.modbusPacketSize && (
                <TableRow>
                  <TableCell>Modbus packet length (bytes)</TableCell>
                  <TableCell>{pkt.modbusPacketSize}</TableCell>
                </TableRow>
              )}
              {pkt.maxPolledFlags && (
                <TableRow>
                  <TableCell>Max polled flags</TableCell>
                  <TableCell>{pkt.maxPolledFlags}</TableCell>
                </TableRow>
              )}
              {payloadItems.map((payloadItem) => {
                const itemValue = pkt[payloadItem.abbreviation.toLowerCase()];
                if (itemValue === undefined) {
                  return null;
                } else {
                  return (
                    <TableRow>
                      <TableCell>{payloadItem.name}</TableCell>
                      <TableCell>{itemValue === null ? 'Missing' : formatReading(itemValue, payloadItem.defaultFormat)}</TableCell>
                    </TableRow>
                  )
                }
              })}
              {polledRows}
            </TableBody>
          </Table>
        ))}
      </Paper>      
    );
  }
}

PacketDecoderForm.propTypes = {
  classes: PropTypes.any.isRequired,
  // match: PropTypes.any.isRequired,  
}

export default withStyles(styles)(withRouter(PacketDecoderForm));
