import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import AppDeviceStore from "../../stores/AppDeviceStore";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DataTable from "../../components/DataTable";
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import DeviceQueueStore from "../../stores/DeviceQueueStore";

function utcMinsToDate(mins) {
  if (mins == null || mins === 0) {
    return null; //not set
  }
  return moment.utc().startOf('day').add(mins,'minutes').local().format('HH:mm');
}

class ApplicationDownlinksDetails extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      modalScheduleEUI: "",
      modalScheduleName: "",
      modalScheduleData: "",
      modalScheduleA1: "Not Set",
      modalScheduleA2: "Not Set",
      modalScheduleA3: "Not Set",
      modalScheduleA4: "Not Set",
      modalScheduleA5: "Not Set",
      modalScheduleA6: "Not Set",
      modalScheduleB1: "Not Set",
      modalScheduleB2: "Not Set",
      modalScheduleB3: "Not Set",
      modalScheduleB4: "Not Set",
      modalScheduleB5: "Not Set",
      modalScheduleB6: "Not Set",
      refresh: "",
    };
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClickOpen = (devEUI, devName, devData) => event => {
    let buffer = Buffer.from(devData, 'base64');

    this.setState({ 
      open: true, 
      modalScheduleEUI: devEUI, 
      modalScheduleName: devName, 
      modalScheduleData: devData,
      modalScheduleA1: utcMinsToDate(buffer.readUInt16BE(1)) || "Not Set",
      modalScheduleA2: utcMinsToDate(buffer.readUInt16BE(3)) || "Not Set",
      modalScheduleA3: utcMinsToDate(buffer.readUInt16BE(5)) || "Not Set",
      modalScheduleA4: utcMinsToDate(buffer.readUInt16BE(7)) || "Not Set",
      modalScheduleA5: utcMinsToDate(buffer.readUInt16BE(9)) || "Not Set",
      modalScheduleA6: utcMinsToDate(buffer.readUInt16BE(11)) || "Not Set",
      modalScheduleB1: utcMinsToDate(buffer.readUInt16BE(13)) || "Not Set",
      modalScheduleB2: utcMinsToDate(buffer.readUInt16BE(15)) || "Not Set",
      modalScheduleB3: utcMinsToDate(buffer.readUInt16BE(17)) || "Not Set",
      modalScheduleB4: utcMinsToDate(buffer.readUInt16BE(19)) || "Not Set",
      modalScheduleB5: utcMinsToDate(buffer.readUInt16BE(21)) || "Not Set",
      modalScheduleB6: utcMinsToDate(buffer.readUInt16BE(23)) || "Not Set"
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleDelete = (devEUI) => event => {
    DeviceQueueStore.flush(devEUI, () => {
      this.setState({ refresh: moment.now()});
    })
  }

  getPage(limit, offset, callbackFunc) {
    AppDeviceStore.listDeviceWithQueue(this.props.application.id, limit, offset, callbackFunc);
  }

  getRow(obj) {
    return(
      <TableRow
        key={obj.devEUI}
        hover
      >
        <TableCell>{obj.name}</TableCell>
        <TableCell>{obj.devEUI}</TableCell>
        <TableCell>{ moment(obj.lastSeenAt).format('YYYY-MM-DD HH:mm:ss') }</TableCell>
        <TableCell>
          { obj.queueItems.length > 0 &&
            <details>
                <summary>Show Pending Downlinks</summary>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell>Frame Cnt</TableCell>
                      <TableCell>Data</TableCell>
                      { this.props.aircomOutput &&
                      <TableCell></TableCell>
                      }      
                    </TableRow>
                  </TableHead>
                  <TableBody> 
                  {obj.queueItems.map((value) => {
                  return <TableRow>
                    <TableCell>{value.fCnt}</TableCell>
                    <TableCell>{Buffer.from(value.data, 'base64')}</TableCell>
                    { this.props.aircomOutput &&
                    <TableCell><IconButton aria-label="View Queued Schedule" color="primary" onClick={this.handleClickOpen(obj.devEUI, obj.name, value.data)}><ViewIcon /></IconButton></TableCell>
                    }
                    </TableRow>
                  })}
                  </TableBody>
                </Table>
            </details>
          }
        </TableCell>
        <TableCell>
          <IconButton aria-label="Flush queued downlinks" color="primary" onClick={this.handleDelete(obj.devEUI)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>EUI</TableCell>
                <TableCell>Last Seen</TableCell>
                <TableCell>Downlinks</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Schedule for {this.state.modalScheduleName} ({this.state.modalScheduleEUI})</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Typography>The following schedule will be downloaded at the next transmission from the device</Typography>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell ></TableCell>
                  <TableCell >Output A</TableCell>
                  <TableCell >Output B</TableCell>
                </TableRow>
              </TableHead>
              <TableBody> 
                  <TableRow key="ModalTime1">
                    <TableCell  component="th" scope="row">
                      Pulse Time 1:
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleA1}
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleB1}
                    </TableCell>
                  </TableRow>
                  <TableRow key="ModalTime2">
                    <TableCell  component="th" scope="row">
                      Pulse Time 2:
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleA2}
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleB2}
                    </TableCell>
                  </TableRow>
                  <TableRow key="ModalTime3">
                    <TableCell  component="th" scope="row">
                      Pulse Time 3:
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleA3}
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleB3}
                    </TableCell>
                  </TableRow>
                  <TableRow key="ModalTime4">
                    <TableCell  component="th" scope="row">
                      Pulse Time 4:
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleA4}
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleB4}
                    </TableCell>
                  </TableRow>
                  <TableRow key="ModalTime5">
                    <TableCell  component="th" scope="row">
                      Pulse Time 5:
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleA5}
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleB5}
                    </TableCell>
                  </TableRow>
                  <TableRow key="ModalTime6">
                    <TableCell  component="th" scope="row">
                      Pulse Time 6:
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleA6}
                    </TableCell>
                    <TableCell >
                        {this.state.modalScheduleB6}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      
    );
  }
}

export default ApplicationDownlinksDetails;
