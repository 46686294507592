import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler, errorHandlerIgnoreNotFoundWithCallback } from "./helpers";
import dispatcher from "../dispatcher";

class AppDeviceGroupPollStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/appDeviceGroupPoll.swagger.json", sessionStore.getClientOpts());
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_Get({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  list(appDeviceGroupID, limit, offset, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_List({
        appDeviceGroupId: appDeviceGroupID,
        limit: limit,
        offset: offset,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  create(appDeviceGroupPoll, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_Create({
        body: {
          appDeviceGroupPoll: appDeviceGroupPoll,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  update(appDeviceGroupPoll, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_Update({
        "appDeviceGroupPoll.id": appDeviceGroupPoll.id,
        body: {
          appDeviceGroupPoll: appDeviceGroupPoll,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_Delete({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "app device type poll has been " + action,
      },
    });
  }

  getPollDataType(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_GetPollDataType({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  listPollDataType(callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupPollService.AppDeviceGroupPollService_PollDataTypeList()
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
}

const appDeviceGroupPollStore = new AppDeviceGroupPollStore();
export default appDeviceGroupPollStore;
