import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';

import Plus from "mdi-material-ui/Plus";

import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import DeviceAdmin from "../../components/DeviceAdmin";
import AppDeviceGroupPollStore from "../../stores/AppDeviceGroupPollStore";

import theme from "../../theme";


const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
};


class ListDeviceGroupPolls extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    AppDeviceGroupPollStore.list(this.props.deviceGroup.id, limit, offset, callbackFunc);
  }

  getRow(obj) {
    // Look up data type name
    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/${this.props.match.params.deviceGroupID}/polls/${obj.id}`}>{obj.pollNumber}</TableCellLink>
        <TableCell>{obj.pollRegCount}</TableCell>
        <TableCell>{obj.pollDataTypeName}</TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Poll Number</TableCell>
                <TableCell>Register Count</TableCell>
                <TableCell>Data Type</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
        <DeviceAdmin organizationID={this.props.match.params.organizationID}>
          <Grid item xs={12} className={this.props.classes.buttons}>
            <Button variant="outlined" className={this.props.classes.button} component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/device-groups/${this.props.deviceGroup.id}/polls/create`}>
              <Plus className={this.props.classes.icon} />
              Create
            </Button>
          </Grid>
        </DeviceAdmin>
      </Grid>
    );
  }
}

ListDeviceGroupPolls.propTypes = {
  classes: PropTypes.any.isRequired,
  deviceGroup: PropTypes.object.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(ListDeviceGroupPolls);
