import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler, errorHandlerIgnoreNotFoundWithCallback } from "./helpers";
import dispatcher from "../dispatcher";

class AppDeviceGroupStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/appDeviceGroup.swagger.json", sessionStore.getClientOpts());
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupService.AppDeviceGroupService_Get({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  list(applicationID, limit, offset, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupService.AppDeviceGroupService_List({
        applicationId: applicationID,
        limit: limit,
        offset: offset,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  create(appDeviceGroup, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupService.AppDeviceGroupService_Create({
        body: {
          appDeviceGroup: appDeviceGroup,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  update(appDeviceGroup, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupService.AppDeviceGroupService_Update({
        "appDeviceGroup.id": appDeviceGroup.id,
        body: {
          appDeviceGroup: appDeviceGroup,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupService.AppDeviceGroupService_Delete({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  pause(id, pause, callbackFunc) {
    this.swagger.then(client => {
      client.apis.AppDeviceGroupService.AppDeviceGroupService_Pause({
        id: id,
        body: {
          pause: pause,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify(pause?"paused":"started");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "app device type has been " + action,
      },
    });
  }
}

const appDeviceGroupStore = new AppDeviceGroupStore();
export default appDeviceGroupStore;
