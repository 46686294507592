import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import AppDeviceStore from "../../stores/AppDeviceStore";
import AppDeviceForm from "./AppDeviceForm";

import PauseAppDeviceForm from "./PauseAppDeviceForm";
import DisableAppDeviceForm from "./DisableAppDeviceForm";


const styles = {
  card: {
    overflow: "visible",
  },
};


class UpdateAppDevice extends Component {
  constructor() {
    super();
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
    this.pauseAppDevice = this.pauseAppDevice.bind(this);
    this.disableAppDevice = this.disableAppDevice.bind(this);
    this.reloadAppDevice = this.reloadAppDevice.bind(this);
  }

  componentDidMount() {
    AppDeviceStore.get(this.props.device.devEUI,this.props.match.params.applicationID, resp => {
      console.log(resp);
      this.setState({
        appDevice: resp.appDevice,
      });
    });
  }

  reloadAppDevice()
  {
    AppDeviceStore.get(this.props.device.devEUI,this.props.match.params.applicationID, resp => {
      console.log(resp);
      this.setState({
        appDevice: resp.appDevice,
      });
    });
  }

  onSubmit(appDevice) {
    AppDeviceStore.update(appDevice, () => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/devices/${this.props.match.params.devEUI}`);
    });
  }

  pauseAppDevice(appDevice) {
    AppDeviceStore.pause(appDevice.id, (appDevice.pausedAt ? false : true) , () => {
      this.reloadAppDevice();
    });
  }

  disableAppDevice(appDevice) {
    AppDeviceStore.disable(appDevice.id, (appDevice.disabledAt ? false : true) , () => {
      this.reloadAppDevice();
    });
  }

  render() {
    if (this.state.appDevice === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <AppDeviceForm
                submitLabel="Update Aircom device"
                object={this.state.appDevice}
                onSubmit={this.onSubmit}
                match={this.props.match}
                update={true}
                disabled={!this.props.admin}
              />
              <PauseAppDeviceForm
                object={this.state.appDevice}
                onSubmit={this.pauseAppDevice}
                match={this.props.match}
                disabled={!this.props.admin}
              />
              <DisableAppDeviceForm
                object={this.state.appDevice}
                onSubmit={this.disableAppDevice}
                match={this.props.match}
                disabled={!this.props.admin}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

UpdateAppDevice.propTypes = {
  admin: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired,
  device: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(UpdateAppDevice));