import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AlarmIcon from '@material-ui/icons/Alarm';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DeviceQueueStore from "../../stores/DeviceQueueStore";
// import DeviceStore from "../../stores/DeviceStore";
import AppDeviceStore from "../../stores/AppDeviceStore";
import { Button, Divider, TextField } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {setBit} from "../../helpers/packetHelper";

const MAX_PVC_SETPOINTS = 36;
const PVCH1_SETPOINTS	= 0;
// const PVCH1_MIN_SETPOINT = 1;
// const PVCH1_MAX_SETPOINT = 2;
// const PVCH1_SETPOINT_INC = 3;
const PVCH1_DEADBAND_UP	= 4;
const PVCH1_DEADBAND_DOWN	= 5;
// const PVCH1_CHECK_FREQ = 6;
// const PVCH1_EXTRA_HEADER = 7;
// const PVCH2_PULSE_SIGNIFICANCE = 8;
// const PVCH2_2_PULSE_THRESHOLD = 9;
// const PVCH2_3_PULSE_THRESHOLD = 10;
// const PVCH2_4_PULSE_THRESHOLD = 12;


/**
 * Return number of mins since 00:00 on given date
 * Will never return zero. (Midnight = 1440)
 * @param {*} date
 */
function utcDateToQuarterHour(date) {
  if (date == null) {
    return 0; //not set
  }
  const dayStart = moment.utc(date).startOf('day');
  let result = moment.utc(date).diff(dayStart, 'minutes') / 15;
  if (result === 0) {
    result = 96; // Zero means undefined
  }
  return Math.round(result);
}

function utcQuarterHourToTimeString(quarterHours) {
  return moment.utc().startOf('day').add(quarterHours * 15, 'minutes').local().format("HH:mm");
}

/**
 * Used to pre-populate time control
 * @param {*} previousQHr 
 * @param {*} previousIncrement 
 */
function getSuggestedSetpointTime(previousQHr) {
  let result = moment.utc().startOf('day');

  const newQuarterHour = ((previousQHr || 0) % 96) + 4;

  if (newQuarterHour < 95) {
    result.add(newQuarterHour * 15, 'minutes');
  }

  return result;

}


/**
 * Get setpoint increment for given min/max
 * Must return same result as the same function
 * in the aircom server ui
 * Where possible, returns numbers with significant digit 1, 5 or 25 (e.g. 1, 0.5, 0.25)
 * @param {*} min
 * @param {*} max
 * @returns
 */
// function getDefaultSetpointIncrement(min, max) {
//   const range = max - min;

//   if (range <= 0) {
//     return 0;
//   }

//   // Calculate default result in case all else fails
//   let result = range / 254;
//   // Round up to 2 decimal places (to ensure range will be covered)
//   result = Math.ceil(result * 100) / 100;

//   // Start again, selecting power of 10 if possible, i.e. 10, 1, 0.1, 0.01
//   for (let exp = -10; exp <= 10; exp++) {
//     if (10 ** exp * 254 >= range) {
//       result = 10 ** exp;
//       break;
//     }
//   }

//   // Can increment be quartered?
//   if (result * 0.25 * 254 >= range) {
//     return result * 0.25;
//   }

//   // Can increment be halved?
//   if (result * 0.5 * 254 >= range) {
//     return result * 0.5;
//   }

//   return result;
// }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class PvControl extends Component {
  constructor() {
    super();
    this.state = {
      selectedDevices: [],
      devices: [],
      minSetpointValue: 20,
      minSetpointText: '20',
      maxSetpointValue: 80,
      maxSetpointText: '80',
      setpointIncrementValue: 0.25,
      setpointIncrementText: '0.25',
      deadbandUpValue: 0.5,
      deadbandUpText: '0.5',
      deadbandDownValue: 0.5,
      deadbandDownText: '0.5',
      defaultSetpointID: 0, // Not configured
      newSetpointTime: moment().startOf('day'),
      newSetpointID: 1, // Minimum value
      dlDeadbandUpChecked: false,
      dlDeadbandDownChecked: false,
      setpoints: [],
    };

    this.queueDownlinks = this.queueDownlinks.bind(this);
    this.handleChangeDevice = this.handleChangeDevice.bind(this);
  }

  queueDownlinks() {
    if (window.confirm("Do you want to queue the new configuration for the selected devices? Any setpoints previously queued, but not downloaded, will be replaced by the new ones.")) {
      let buffer = Buffer.alloc(50);
      
      let {
        selectedDevices,
        setpoints,
        dlDeadbandUpChecked,
        dlDeadbandDownChecked,
        deadbandUpValue,
        deadbandDownValue
      } = this.state;

      setpoints.sort(
        (a, b) => (a.setpointTime % 96) - (b.setpointTime % 96),
      );

      const send2Messages = this.getDownlinkSize() > 50;

      let msg1Data;
      let msg2Data;
      let spHeader;
      let spHeaderBit;
      let spHeaderBytePos;
      let spHeaderPos;

      // Construct setpoint message first

      // Write downlink type. 2 = PV Control Configuration
      buffer.writeUInt8(2, 0);
      let header = 0;
      let headerPos = 1;
      let headerByteCount = 1;
      let dataPos = headerPos + headerByteCount;

      if (setpoints.length > 0) {
        spHeaderPos = headerPos + headerByteCount;
        dataPos = spHeaderPos + 12;
        // 12-bits after header indicate each of 96x quarter-hour times
        header = setBit(header, PVCH1_SETPOINTS);
        let pointsWritten = 0;
        setpoints.forEach((value) => {
          if (
            value.setpointTime > 0 &&
            value.setpointTime <= 96 &&
            value.setpointValueId > 0 &&
            value.setpointValueId <= 255
          ) {
            spHeaderBytePos = spHeaderPos + Math.floor((value.setpointTime % 96) / 8);
            spHeaderBit = value.setpointTime % 8;
            spHeader = buffer.readUInt8(spHeaderBytePos);
            spHeader = setBit(spHeader, spHeaderBit);
            buffer.writeUInt8(spHeader, spHeaderBytePos);
            buffer.writeUInt8(value.setpointValueId, dataPos);
            dataPos += 1;
            pointsWritten += 1;
          }
        });
        if (pointsWritten > 0) {
          if (headerByteCount == 2) {
            buffer.writeUInt16(header, headerPos);
          }
          else
          {
            buffer.writeUInt8(header, headerPos);
          }
        }
        if (send2Messages) {
          msg2Data = buffer.toString('base64', 0, dataPos);
          buffer.fill(0);
          buffer.writeUInt8(2, 0); // Downlink type
          headerPos = 1;
          header = 0;
          dataPos = headerPos + 1;
        }
      }

      if (dlDeadbandUpChecked) {
        header = setBit(header, PVCH1_DEADBAND_UP);
        buffer.writeFloatLE(deadbandUpValue, dataPos);
        dataPos += 4;
      }

      if (dlDeadbandDownChecked) {
        header = setBit(header, PVCH1_DEADBAND_DOWN);
        buffer.writeFloatLE(deadbandDownValue, dataPos);
        dataPos += 4;
      }

      buffer.writeUInt8(header, headerPos);
      msg1Data = buffer.toString('base64', 0, dataPos);

      selectedDevices.forEach((device) => {
        const queueItem = {
          devEUI: device.devEui,
          f_port: 2,
          data: msg1Data,
        }

        DeviceQueueStore.flush(device.devEui, () => {
          DeviceQueueStore.enqueue(queueItem, () => {
            if (send2Messages) {
              queueItem.data = msg2Data;
              DeviceQueueStore.enqueue(queueItem, () => {});
            }
          })
        })
      }  
      );
    }
  };

  handleChangeDevice(event) {
    this.setState({ selectedDevices: event.target.value });
  }

  componentDidMount() {
    const {applicationID} = this.props.match.params; //M.O. 2022-03-15 Seems to be empty!
    //NOTE: appDeviceGroupID: 0 means no group filter and outputControlType: 2 means PV control
    AppDeviceStore.listByOutputType(this.props.application.id,0,2,9999,0, resp => {
      this.setState({
        devices: resp.result
      });
    });
  }

  // onChangeMinSetpoint = (e) => {
  //   let {
  //     minSetpointValue,
  //     maxSetpointValue,
  //     setpointIncrementValue,
  //     setpointIncrementText
  //   } = this.state;
  //   const minSetpointText = e.target.value;
  //   minSetpointValue = Number.parseFloat(minSetpointText);
  //   // Try to set setpointIncrementValue
  //   if (maxSetpointValue > minSetpointValue) {
  //     setpointIncrementValue = getDefaultSetpointIncrement(minSetpointValue, maxSetpointValue);
  //     setpointIncrementText = setpointIncrementValue.toString();
  //   }
  //   this.setState({
  //     minSetpointText,
  //     minSetpointValue,
  //     setpointIncrementValue,
  //     setpointIncrementText
  //   })
  // };

  // onChangeMaxSetpoint = (e) => {
  //   let {
  //     maxSetpointValue,
  //     minSetpointValue,
  //     setpointIncrementValue,
  //     setpointIncrementText
  //   } = this.state;
  //   const maxSetpointText = e.target.value;
  //   maxSetpointValue = Number.parseFloat(maxSetpointText);
  //   // Try to set setpointIncrementValue
  //   if (maxSetpointValue > minSetpointValue) {
  //     setpointIncrementValue = getDefaultSetpointIncrement(minSetpointValue, maxSetpointValue);
  //     setpointIncrementText = setpointIncrementValue.toString();
  //   }
  //   this.setState({
  //     maxSetpointText,
  //     maxSetpointValue,
  //     setpointIncrementValue,
  //     setpointIncrementText
  //   })
  // };

  // onChangeSetpointIncrement = (e) => {
  //   let {
  //     setpointIncrementValue,
  //   } = this.state;
  //   const setpointIncrementText = e.target.value;
  //   setpointIncrementValue = Number.parseFloat(setpointIncrementText);
  //   // Don't touch min and max setpoints
  //   this.setState({
  //     setpointIncrementValue,
  //     setpointIncrementText
  //   })
  // };

  onChangeDeadbandUp = (e) => {
    const deadbandUpText = e.target.value;
    const deadbandUpValue = Number.parseFloat(deadbandUpText);
    this.setState({
      deadbandUpText,
      deadbandUpValue,
      dlDeadbandUpChecked: true,
    })
  };

  onChangeDeadbandDown = (e) => {
    const deadbandDownText = e.target.value;
    const deadbandDownValue = Number.parseFloat(deadbandDownText);
    this.setState({
      deadbandDownText,
      deadbandDownValue,
      dlDeadbandDownChecked: true,
    })
  };

  // onChangeDefaultSetpointID = (e) => {
  //   const defaultSetpointID = e.target.value;
  //   this.setState({defaultSetpointID});
  // };

  newSetpointTimeChanged = date => {
    // Round to nearest 15 mins (user could have amended text input value)
    date.setMinutes(Math.round(date.getMinutes() / 15) * 15);
    this.setState({newSetpointTime: date});
  };

  addSetpoint = () => {
    let {setpoints, newSetpointTime, newSetpointID} = this.state;
    if (setpoints.length >= MAX_PVC_SETPOINTS) {
      return;
    }
    const newSetpointTimeQHrs = utcDateToQuarterHour(newSetpointTime);
    // Delete existing setpoint with same date
    let newSetpoints = setpoints.filter(value => value.setpointTime !== newSetpointTimeQHrs);
    newSetpoints.push({
      setpointTime: newSetpointTimeQHrs,
      setpointValueId: newSetpointID,
    });
    newSetpoints.sort(
      (a, b) => (a.setpointTime % 96) - (b.setpointTime % 96),
    )

    // Set newSetpointTime to suggest next time to add
    newSetpointTime = getSuggestedSetpointTime(newSetpointTimeQHrs);

    this.setState({
      setpoints: newSetpoints,
      newSetpointTime,
    });
  }

  deleteSetpoint = (index) => {
    let {setpoints} = this.state;
    if (setpoints.length === 0 || index < 0 || index >= setpoints.length) {
      return;
    }
    setpoints.splice(index, 1);
    this.setState({setpoints});
  }

  onSetpointValueChanged = (index, value) => {
    let newSetpoints = this.state.setpoints;
    if (index >= 0 && index < newSetpoints.length) {
      const newId = value ? value.id : 0;
      newSetpoints[index].setpointValueId = newId;
      this.setState({setpoints: newSetpoints});
    }
  };

  getDownlinkSize = () => {
    const {dlDeadbandUpChecked, dlDeadbandDownChecked, setpoints} = this.state;

    const headerBytes = 1; // Amend if other config items included

    let downlinkBytes = 0;
    if (dlDeadbandUpChecked) {
      downlinkBytes += 4;
    }
    if (dlDeadbandDownChecked) {
      downlinkBytes += 4;
    }
    if (setpoints.length > 0) {
      downlinkBytes = downlinkBytes + 12 + setpoints.length;
    }

    if (downlinkBytes > 0) {
      return downlinkBytes + headerBytes;
    }

    return 0;
  }

  render() {
    const {
      devices,
      selectedDevices,
      minSetpointText,
      maxSetpointText,
      setpointIncrementText,
      minSetpointValue,
      maxSetpointValue,
      setpointIncrementValue,
      deadbandUpText,
      deadbandDownText,
      setpoints,
      dlDeadbandUpChecked,
      dlDeadbandDownChecked,
      // defaultSetpointID,
    } = this.state;
    let {newSetpointTime} = this.state;

    const downlinkSize = this.getDownlinkSize();
    const downlinkPackets = Math.ceil(downlinkSize / 50);

    if (newSetpointTime === 0) {
      newSetpointTime = utcDateToQuarterHour(moment.utc());
    }

    // Check if need to warn max setpoint out of range
    // const maxPossibleSetpoint = minSetpointValue + (254 * setpointIncrementValue);
    // const maxOutofRangeWarning = maxPossibleSetpoint < maxSetpointValue ? 'Max setpoint out of range' : '';
    let setpointOptions = [{label: 'Not configured', id: 0}];
    let setpointValue = minSetpointValue;
    for (let i = 0; i < 254; i++) {
      if (setpointValue >= maxSetpointValue) {
        setpointOptions.push({label: maxSetpointValue.toString(), id: i + 1});
        break;
      }
      setpointOptions.push({label: setpointValue.toString(), id: i + 1});
      setpointValue = setpointValue + setpointIncrementValue;
    }

    // let setpoints = [
    //   {setpointTime: 60, setpointValueId: 50}
    // ]
    const sendButtonEnabled = (downlinkSize > 0) && (selectedDevices.length > 0);

    return(
      <Grid item xs={12}>
        <Paper style={{
          padding: 8,
          marginTop: 8,
        }}>
        <Grid item>
          <Typography style={{marginBottom:8}} variant="h6" color="inherit">
              Device Configuration
          </Typography>
        <Grid item>
          <TextField
            type="text"
            // onChange={this.onChangeMinSetpoint}
            label="Minimum Setpoint"
            // helperText="Changing these will clear all setpoints"
            value={minSetpointText}
            disabled
          />
          <TextField
            type="text"
            // onChange={this.onChangeMaxSetpoint}
            label="Maximum Setpoint"
            // helperText={maxOutofRangeWarning}
            value={maxSetpointText}
            disabled
          />
          <TextField
            type="text"
            onChange={this.onChangeSetpointIncrement}
            label="Setpoint Increment"
            value={setpointIncrementText}
            disabled
          />
          </Grid>
          <Grid item>
            <Typography>
              <TextField
                type="text"
                onChange={this.onChangeDeadbandUp}
                label="Deadband (Up)"
                value={deadbandUpText}
              />
              <Checkbox
                color="primary"
                checked={dlDeadbandUpChecked}
                onChange={() => this.setState({dlDeadbandUpChecked: !dlDeadbandUpChecked})}
              />
              Include in downlink
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <TextField
                type="text"
                onChange={this.onChangeDeadbandDown}
                label="Deadband (Down)"
                value={deadbandDownText}
              />
              <Checkbox
                color="primary"
                checked={dlDeadbandDownChecked}
                onChange={() => this.setState({dlDeadbandDownChecked: !dlDeadbandDownChecked})}
              />
              Include in downlink
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Divider />
          <Typography style={{marginTop: 8, marginBottom:8}} variant="h6" color="inherit">
            PV Setpoints
          </Typography>
        </Grid>

        <Grid container spacing={2}>
        <Grid item xs={5}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell >ID</TableCell>
                <TableCell >Local Time</TableCell>
                <TableCell >Setpoint</TableCell>
                <TableCell ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>
                <TableCell>
                  <Autocomplete
                    id="id"
                    options={setpointOptions}
                    getOptionLabel={option => option.label}
                    value={setpointOptions[0]}
                    onChange={(event, newValue) => {
                      this.setState({setpointValueId: newValue});
                    }}                    
                    renderInput={params => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </TableCell>
              </TableRow> */}
              {setpoints.map((value, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                  <TableCell >
                    {utcQuarterHourToTimeString(value.setpointTime)}
                  </TableCell>
                  <TableCell >
                    <Autocomplete
                      id={"item" + index.toString()}
                      autoHighlight
                      options={setpointOptions}
                      getOptionLabel={option => option.label}
                      // getOptionSelected={(option, value) => option.id === value.id}
                      value={setpointOptions[value.setpointValueId || 0]}
                      onChange={(e, v) => {this.onSetpointValueChanged(index, v)}}
                      renderInput={params => (
                        <TextField {...params} variant="standard" />
                      )}
                      style={{ width: 270 }}
                    />
                  </TableCell>
                  <TableCell>
                    <DeleteIcon onClick={() => this.deleteSetpoint(index)} />
                  </TableCell>
                </TableRow>
                )
              })}
              <TableRow key="AddSetpoint">
                <TableCell colSpan={2}>
                <Typography color="inherit">
                  New Setpoint
                </Typography>
                  <KeyboardTimePicker 
                    ampm={false}
                    emptyLabel="Not Set"
                    value={this.state.newSetpointTime} 
                    onChange={this.newSetpointTimeChanged} 
                    keyboardIcon={<AlarmIcon />}
                    minutesStep={15}
                    />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<AddIcon></AddIcon>}
                    onClick={this.addSetpoint}
                    disabled={setpoints.length >= MAX_PVC_SETPOINTS}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </MuiPickersUtilsProvider>
          </Grid>
          </Grid>
          <Grid item xs={1}>
            <Typography>
              Apply to:&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={3}>
        
            <Select
              id="devices"
              multiple
              value={selectedDevices}
              onChange={this.handleChangeDevice}
              input={<Input id="select-devices" />}
              renderValue={(selected) => (
                <div style={{display: 'flex',
                  flexWrap: 'wrap',}}>
                  {selected.map((value) => (
                    <Chip key={value.devEui} label={value.name} style={{
                      margin: 2,
                    }} />
                  ))}
                </div>
              )}
              displayEmpty={true}
              MenuProps={MenuProps}
            >
              {devices.map((device) => (
                <MenuItem key={device.devEui} value={device}>
                  <Checkbox color="primary" checked={selectedDevices.indexOf(device) > -1} />
                  <ListItemText primary={device.name} />
                </MenuItem>
              ))}
            </Select>
       
          </Grid>
          <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon></SendIcon>}
            onClick={() => { this.queueDownlinks(); }}
            disabled={!sendButtonEnabled}
          >
            Send
          </Button>
          </Grid>
          {(downlinkSize > 0) && (
            <Typography style={downlinkPackets > 1 ? {color: "red"} : {}}>
              Downlink packets:&nbsp;
              {downlinkPackets}
              &nbsp;(Total bytes:&nbsp;
              {downlinkSize}
              )
            </Typography>
          )}
        </Paper>
      </Grid>
    );
  }
}

PvControl.propTypes = {
  match: PropTypes.any.isRequired,
}

export default PvControl;
