import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import OrganizationStore from "../../stores/OrganizationStore";
import SessionStore from "../../stores/SessionStore";
import applicationStore from "../../stores/ApplicationStore";


/**
 * Called whenvever "/" chosen, esp after initial login
 */
class OrganizationRedirect extends Component {
  componentDidMount() {
    // Try to set organization and app from local storage or session store
    let organizationID = SessionStore.getOrganizationID();
    let applicationID = localStorage.getItem("applicationID");

    if ((Number(organizationID) > 0) && (Number(applicationID) > 0)) {
      this.props.history.push(`/organizations/${organizationID}/applications/${applicationID}/dashboard`);
      return;
    }

    if (Number(organizationID) > 0) {
      // Fetch application
      applicationStore.list("", organizationID, 1, 0, (resp) => {
        if (resp.totalCount > 0) {
          applicationID = resp.result[0].id;
          this.props.history.push(`/organizations/${organizationID}/applications/${applicationID}/dashboard`);
        }
      });
      return;
    }
    
    // If code reaches here we need to query organization and application
    OrganizationStore.list("", 1, 0, resp => {
      if (resp.result.length > 0) {
        organizationID = resp.result[0].id;
        applicationStore.list("", organizationID, 1, 0, (resp) => {
          if (resp.totalCount > 0) {
            applicationID = resp.result[0].id;
            this.props.history.push(`/organizations/${organizationID}/applications/${applicationID}/dashboard`);
          } else {
            // No applications for given organization
            this.props.history.push(`/organizations/${organizationID}`);            
          }
        });
      }
    });
  }

  render() {
    return(<div></div>);
  }
}

OrganizationRedirect.propTypes = {
  history: PropTypes.any.isRequired,
}

export default withRouter(OrganizationRedirect);
