import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import 'react-datetime/css/react-datetime.css';
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#054F8D',
    },
    secondary: {
      main: '#4888C8',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const styles = theme => ({
  pageDiv: {
    marginTop: '70px',
    marginLeft: '20px',
    width: '100%',
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    width: '90%',
    // overflowX: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  lastPacketContainer: {
    marginTop: 20,
  },
  table: {
    width: '100%'
  },
  tableRow: {
    height: 40
  },
  tableCell: {
    padding: 4
  },
  button: {
    margin: theme.spacing.unit,
  },
  darkLabel: {
    color: '#000000',
  },
  checkBoxControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  formControlLabel: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 250,
  },
  formControl: {
    fontSize: 50,
  },
  mediumFormControlLabel: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 350,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 250,
  },
  mediumTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
  },
  wideTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 500,
  },
  selectField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  mediumSelectField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
  },
  alertError: {
    backgroundColor: red[600],
  },
  alertSuccess: {
    backgroundColor: green[600],
  },
  graphDiv: {
    width: '80%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  gridItem: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  card: {
    minWidth: 75,
  },
  cardTitle: {
    fontSize: 14,
  },
  cardTime: {
    fontSize: 10,
  },
  errorIcon: {
    color: "#FF0000",
    margin: theme.spacing.unit,
    fontSize: 24,
  },
  rdtInput: {
    font:"inherit",
  },
  rdtInputError: {
    font:"inherit",
    backgroundColor:"#FF0000",
  },
  td: {
    width:"100px",
  },
  redDigital: {
    color: "#FF0000",
  },
  greenDigital: {
    color: "#4caf50",
  },
  greyDigital: {
    color: "#90a4ae",
  },
  digitalTd: {
    textAlign: 'center',
  }
  
});
