import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import OrganizationStore from "../../stores/OrganizationStore";
import SessionStore from "../../stores/SessionStore";

import Grid from "@material-ui/core/Grid";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";

import SettingsIcon from "@material-ui/icons/Settings";
import { withStyles } from "@material-ui/core/styles";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import theme from "../../theme";
import ListApplications from "../applications/ListApplications";
import ListOrganizationUsers from "./ListOrganizationUsers";
import OrganizationLayout from "./OrganizationLayout";
import ListGateways from "../gateways/ListGateways";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class OrganizationDashboard extends Component {
  constructor() {
    super();

    this.state = {
      organization: null,
      tab: 0,
      admin: false,
    };
    this.locationToTab = this.locationToTab.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    SessionStore.on("change", this.setIsAdmin);
    this.loadData();
    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }
    this.locationToTab();
  }

  loadData = () => {
    OrganizationStore.get(this.props.match.params.organizationID, resp => {
      this.setState({
        organization: resp,
      });
    });
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.endsWith("/users")) {
      tab = 1;
    }

    if (window.location.href.endsWith("/gateways")) {
      tab = 2;
    }

    if (window.location.href.endsWith("/details")) {
      tab = 3;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (!this.state.organization) {
      return <div></div>;
    }
    const {admin} = this.state;
    const {organizationID} = this.props.match.params;
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={admin && (
            [
              <TitleBarButton
                key={2}
                icon={<SettingsIcon />}
                label="Org. Setup"
                to={`/organizations/${organizationID}/organization-setup`}
              />
            ]
          )}
        >
          <TitleBarTitle title={`Organization (${this.state.organization.organization.name})`} />
        </TitleBar>
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab value={0} label="Applications" component={Link} to={`/organizations/${organizationID}`} />
            {admin && (
              <Tab value={1} label="Users" component={Link} to={`/organizations/${organizationID}/users`} />
            )}
            <Tab value={2} label="Gateways" component={Link} to={`/organizations/${organizationID}/gateways`} />
            {admin && (
              <Tab value={3} label="Details" component={Link} to={`/organizations/${organizationID}/details`} />
            )}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Route exact path={`/organizations/${organizationID}/gateways`} render={props => <ListGateways organizationID={organizationID} admin={admin} {...props} />} />
          {admin && (
          <Route exact path={`/organizations/${organizationID}/details`} render={props => <OrganizationLayout organizationID={organizationID} admin={admin} {...props} />} />
          )}
          {admin && (
            <Route exact path={`/organizations/${organizationID}/users`} render={props => <ListOrganizationUsers organizationID={organizationID} admin={admin} {...props} />} />
          )}
          <Route exact path={`/organizations/${organizationID}`} render={props => <ListApplications organizationID={organizationID} admin={admin} {...props} />} />
        </Grid>
      </Grid>
    );
  }
}

OrganizationDashboard.propTypes = {
  classes: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(OrganizationDashboard));
