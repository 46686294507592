import React from "react";

import Typography from "@material-ui/core/Typography";

function AccessDenied() {
  return (
    <div>
      <Typography>
        Sorry. You are not authorized to view this page.
      </Typography>
    </div>
  );
}

export default AccessDenied;
