import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";

// import TitleBar from "../../components/TitleBar";
// import TitleBarTitle from "../../components/TitleBarTitle";
// import TitleBarButton from "../../components/TitleBarButton";
import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";

import UserStore from "../../stores/UserStore";
import Button from '@material-ui/core/Button';
import theme from "../../theme";

const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
};

class ListUsers extends Component {
  getPage(limit, offset, callbackFunc) {
    UserStore.list("", limit, offset, callbackFunc);
  }

  getRow(obj) {
    let active = null;
    let admin = null;

    if (obj.isAdmin) {
      admin = <Check />;
    } else {
      admin = <Close />;
    }

    if (obj.isActive) {
      active = <Check />;
    } else {
      active = <Close />;
    }

    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/system/users/${obj.id}`}>{obj.email}</TableCellLink>
        <TableCell>{active}</TableCell>
        <TableCell>{admin}</TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        {/* <TitleBar
          title="Users"
          buttons={[
            <TitleBarButton
              key={1}
              label="Create"
              icon={<Plus />}
              to={`/users/create`}
            />,
          ]}
        >
          <TitleBarTitle title="Users" />
        </TitleBar> */}
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Admin</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
        {this.props.admin && (
          <Grid item xs={12} className={this.props.classes.buttons}>
            <Button variant="outlined" className={this.props.classes.button} component={Link} to={"/system/users/create"}>
              <Plus className={this.props.classes.icon} />
                Create User
              </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

ListUsers.propTypes = {
  admin: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired
}

export default withStyles(styles)(ListUsers);
