import React, { Component } from "react";
import PropTypes from "prop-types";

import DeviceReadingsTable from '../components/DeviceReadingsTable';
import DeviceReadingsCards from '../components/DeviceReadingsCards';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

// import moment from "moment";
import UpPacketsLog from "./UpPacketsLog";
import DeviceReadingGraph from "./DeviceReadingGraph";

export default class DashCard extends Component {
  constructor() {
    super();

    this.state = {
      cardContent: null,
      dataLoaded: false,
      latestReadingsByDevice: null,
    };
  }

  // componentDidMount() {
  // }

  render() {
    const {
      cardType,
      timeLo,
      timeHi,
      deviceFilter,
      filterName,
      applicationID,
      organizationID,
      appItemIds,
      itemProps,
      cacheCounter,
    } = this.props;
    
    switch (cardType) {
      case 1:
        return(
          <DeviceReadingsCards applicationID={applicationID} displayStyle="1" deviceFilter={deviceFilter} filterName={filterName} timeHi={timeHi} appItemIds={appItemIds} />
        );
        break;

      case 2:
        return(
            <DeviceReadingsTable applicationID={applicationID} displayStyle="0" deviceFilter={deviceFilter} filterName={filterName} timeHi={timeHi} />
        );
        break;

      case 3:
        return(
            <UpPacketsLog applicationID={applicationID} organizationID={Number(organizationID)} deviceFilter={deviceFilter} filterName={filterName} timeLo={timeLo} timeHi={timeHi} groupId={0} cacheCounter={cacheCounter} />
        );
        break;

      case 4:
        return(
            <DeviceReadingGraph
              applicationID={applicationID}
              deviceFilter={deviceFilter}
              filterName={filterName}
              timeLo={timeLo}
              timeHi={timeHi}
              title={itemProps.label}
              appItemId={itemProps.appItemId}
              isSpotlit={itemProps.isSpotlit}
              addSpotlight={itemProps.addSpotlight}
              clearSpotlight={itemProps.clearSpotlight}
            />
        );
        break;

      default:
        break;
    }    
    return(
      <Card>
        <CardHeader title="Unknown Card" />
        <CardContent>
          <Typography>
            Unknown card
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

DashCard.propTypes = {
  appItemIds: PropTypes.array.isRequired,
  applicationID: PropTypes.number.isRequired,
  cardType: PropTypes.number.isRequired,
  deviceFilter: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  organizationID: PropTypes.number.isRequired,
  itemProps: PropTypes.any.isRequired,
  timeLo: PropTypes.string.isRequired,
  timeHi: PropTypes.string.isRequired,
  cacheCounter: PropTypes.any,
}

DashCard.defaultProps = {
  cacheCounter : 0,
}