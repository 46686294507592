import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";

import ApplicationStore from "../../stores/ApplicationStore";
import SessionStore from "../../stores/SessionStore";
import AppItemStore from "../../stores/AppItemStore";

import UpdateApplicationItem from "./UpdateApplicationItem";

import Back from "mdi-material-ui/ArrowLeft";

const styles = {
};

class ApplicationItemLayout extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
    };

    this.deleteApplicationItem = this.deleteApplicationItem.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    AppItemStore.get(this.props.match.params.applicationItemID, resp => {
      this.setState({
        applicationItem: resp.appItem,
      });
    });

    // DeviceStore.on("update", this.getDevice);
    SessionStore.on("change", this.setIsAdmin);

    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
    // DeviceStore.removeListener("update", this.getDevice);
  }

  // componentDidUpdate(oldProps) {
  //   if (this.props === oldProps) {
  //     return;
  //   }

  //   this.locationToTab();
  // }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationDeviceAdmin(this.props.match.params.organizationID),
    });
  }

  deleteApplicationItem() {
    if (window.confirm("Are you sure you want to delete this item?")) {
      AppItemStore.delete(this.props.match.params.applicationItemID, () => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`);
      });
    }
  }

  render() {
    if (this.state.application === undefined || this.state.applicationItem === undefined) {
      return(<div></div>);
    }
    const {admin} = this.state;
    const titleButtons = admin ? [
      <TitleBarButton
        label="Item List"
        icon={<Back/>}
        color="primary"
        to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`}
      />,
      <TitleBarButton
        label="Delete"
        icon={<Delete />}
        color="secondary"
        onClick={this.deleteApplicationItem}
      />
    ] : null;


    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={titleButtons}>
          <TitleBarTitle title="Application Item" />
        </TitleBar>

        <Grid item xs={12}>
          <Switch>
            <Route exact path={`${this.props.match.path}`} render={props => <UpdateApplicationItem applicationItem={this.state.applicationItem} admin={this.state.admin} {...props} />} />  
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

ApplicationItemLayout.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default withStyles(styles)(withRouter(ApplicationItemLayout));
