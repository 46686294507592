import React, { Component } from "react";
import PropTypes from "prop-types";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ValueGraph from "../components/ValueGraph";
import HighlightIcon from '@material-ui/icons/Highlight';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import AutocompleteSelect from "./AutocompleteSelect";
import { styles } from '../styles/aircom-styles';
import moment from "moment";

import UpPacketStore from "../stores/UpPacketStore";
import AppItemStore from "../stores/AppItemStore";

/**
 * 
 * @param {*} data 
 * @returns array with counter increments multiplied by time diff and daily rate
 */
function ApplyCounterIncrements(data, periodic, timeLo, timeHi) {
  let itemData = [];
  const scale = Number(data[0].format.substr(3, 50));

  if (!periodic) {
    // e.g. windspeed
    for (let i = 1; i < data.length; i++) {
      let countInc = data[i].value - data[i - 1].value;
      if (countInc < 0) {
        countInc = 0;
      }
      const dayDiff = moment(data[i].receivedAt).diff(moment(data[i - 1].receivedAt), 'days', true);
      itemData.push({
        value: countInc / (dayDiff * scale),
        receivedAt: data[i].receivedAt,
        format: "0.00",
      });
    }
    return itemData;
  }

  // Must be periodic, e.g. rainfall per hour or per day
  const dayDiff = moment(timeHi).diff(moment(timeLo), 'days', true);
  // const hourDiff = moment(timeHi).diff(moment(timeLo), 'hours', true);
  const periodUnit = (dayDiff < 2) ? 'hour': 'day';
  
  let currentPeriodStart = 0;
  let counter = null;
  data.forEach((row) => {
    let rowPeriodStart = moment(row.receivedAt).startOf(periodUnit).valueOf();
    if (rowPeriodStart > currentPeriodStart) {
      if (counter !== null) {
        // First row of data only used for start counter
        itemData.push({
          value: (row.value - counter) * scale,
          receivedAt: row.receivedAt,
          format: "CP_0.00",
        })
      }
      counter = row.value;
      currentPeriodStart = rowPeriodStart;
    }
  });
  return itemData;
}

export default class DeviceReadingGraph extends Component {
  constructor() {
    super();

    this.state = {
      fetchingData: false,
      graphData: null,
      appItem2Id: null,
    };
    this.fetchData = this.fetchData.bind(this);
    this.onAppItem2Change = this.onAppItem2Change.bind(this);
    this.getAppItemOption = this.getAppItemOption.bind(this);
    this.getAppItemOptions = this.getAppItemOptions.bind(this);
  }

  componentDidUpdate(prevProps){
    if ((prevProps === this.props) || this.state.fetchingData) {
      return;
    }

    //only do this if any of the filter has changed, otherwise this is called recursively
    if(
      (prevProps.deviceFilter !== this.props.deviceFilter) ||
      (prevProps.timeLo !== this.props.timeLo) ||
      (prevProps.timeHi !== this.props.timeHi) 
    ){
      this.fetchData(prevProps.deviceFilter !== this.props.deviceFilter); // Clear so previous data no longer showing
    }
  }

  componentDidMount() {
    this.fetchData();  
  }

  fetchData(clearDataFirst) {
    const {deviceFilter, timeLo, timeHi, appItemId, isSpotlit} = this.props;
    const {appItem2Id} = this.state;

    if (!deviceFilter) {
      return;
    }
    
    let graphData = (clearDataFirst === true) ? null : this.state.graphData;
    this.setState({fetchingData: true, graphData}, () => {
      graphData = [];
      UpPacketStore.getReadingsInRange(this.props.applicationID, appItemId, deviceFilter, timeLo, timeHi, resp => {
        // Adjust to show adjusted counter increments
        if (resp.items.length > 0 && resp.items[0].format.startsWith('CI_')) {
          graphData.push(ApplyCounterIncrements(resp.items, false, 0, 0));
        } else if (resp.items.length > 0 && resp.items[0].format.startsWith('CP_')) {
          graphData.push(ApplyCounterIncrements(resp.items, true, timeLo, timeHi));
        } else {
          graphData.push(resp.items);
        }
        
        if (isSpotlit && (appItem2Id !== null)) {
          UpPacketStore.getReadingsInRange(this.props.applicationID, appItem2Id, deviceFilter, timeLo, timeHi, resp => {
            // Adjust to show adjusted counter increments
            if (resp.items.length > 0 && resp.items[0].format.startsWith('CI_')) {
              graphData.push(ApplyCounterIncrements(resp.items, false, 0, 0));
            } else if (resp.items.length > 0 && resp.items[0].format.startsWith('CP_')) {
              graphData.push(ApplyCounterIncrements(resp.items, true, timeLo, timeHi));
            } else {
              graphData.push(resp.items);
            }
            this.setState({graphData, fetchingData: false});
          });
        } else {
          this.setState({graphData, fetchingData: false});
        }
      });
    })
  };

  onAppItem2Change(e) {
    // e.target.value is null if cleared
    if (e.target.value === null && this.state.graphData.length > 1) {
      const {graphData} = this.state;
      this.setState({appItem2Id: null, graphData: [graphData[0]]}, () => this.fetchData(false));
    } else {
      this.setState({appItem2Id: e.target.value}, () => this.fetchData(false));
    }
  }

  getAppItemOption(id, callbackFunc) {
    AppItemStore.get(id, resp => {
      callbackFunc({label: resp.appItem.name, value: resp.appItem.id});
    });
  }

  getAppItemOptions(search, callbackFunc) {
    const appId = this.props.applicationID;
    AppItemStore.list(appId, 50, 0, resp => {
      const options = resp.result.map((o) => {return {label: o.name, value: o.id}});
      callbackFunc(options, resp.totalCount);
    });
  }

  render() {
    const {title, timeLo, timeHi, isSpotlit, addSpotlight, clearSpotlight, appItemId} = this.props;
    let {appItem2Id, graphData} = this.state;

    const dataLoaded = graphData !== null;
    const noData = dataLoaded && (Array.isArray(graphData) && graphData.length === 0);

    const infoMessage = noData ? 'No data' : 'Loading data...';

    return(
      <Grid item xs={12}>
        {/* <Typography variant="h5" color="inherit">
          {!isSpotlit && (
            <IconButton onClick={() => addSpotlight(appItemId)}>
              <HighlightIcon />
            </IconButton>
          )}
          {isSpotlit && (
            <IconButton onClick={clearSpotlight}>
              <HighlightOffIcon />
            </IconButton>
          )}
          &nbsp;Reading Graph
          {this.state.fetchingData ? "..." : ""}
        </Typography> */}

        {!isSpotlit && (
          <Typography variant="h5" color="inherit">
            <IconButton onClick={() => addSpotlight(appItemId)}>
              <HighlightIcon />
            </IconButton>
            &nbsp;
            {title}
            {this.state.fetchingData ? "..." : ""}
          </Typography>
        )}
        
        {isSpotlit && (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography variant="h5" color="inherit">
                  1.&nbsp;
                  {title}
                  <IconButton onClick={clearSpotlight}>
                    <HighlightOffIcon />
                  </IconButton>
                  {this.state.fetchingData ? "..." : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" color="inherit">
                  2.
                </Typography>
              </Grid>
              <Grid item xs={3}>
              <Typography variant="h5" color="inherit">
                <AutocompleteSelect
                  id="appItem2Id"
                  margin="none"
                  value={appItem2Id}
                  onChange={this.onAppItem2Change}
                  getOption={this.getAppItemOption}
                  getOptions={this.getAppItemOptions}
                  className={styles.select}
                  clearable
                  // triggerReload={this.state.cacheCounter}
                />
              </Typography>
              </Grid>
            </Grid>
          </div>
        )}

      {(!dataLoaded || noData) && (
          <Grid item>
            <Typography variant="h5" color="inherit">
              {title}
            </Typography>
            <Typography>
              {infoMessage}
            </Typography>
          </Grid>
      )}
      {dataLoaded && (
        <ValueGraph graphData={graphData} value={'value'} timeLo={timeLo} timeHi={timeHi} title={title} />
      )}
      </Grid>
    );
  }
}

DeviceReadingGraph.propTypes = {
  addSpotlight: PropTypes.func.isRequired,
  clearSpotlight: PropTypes.func,
  appItemId: PropTypes.any.isRequired,
  // appItem2Id: PropTypes.any,
  applicationID: PropTypes.any.isRequired,
  isSpotlit: PropTypes.any,
  deviceFilter: PropTypes.string.isRequired,
  timeLo: PropTypes.string.isRequired,
  timeHi: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

DeviceReadingGraph.defaultProps = {
  // appItem2Id: null,
  clearSpotlight: null,
  isSpotlit: false,
}
