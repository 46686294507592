import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";

import EditIcon from "@material-ui/icons/Edit";
// import SettingsIcon from "@material-ui/icons/Settings";


import ApplicationStore from "../../stores/ApplicationStore";
import SessionStore from "../../stores/SessionStore";

// import theme from "../../theme";


const styles = {
};


class ApplicationEvents extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };

  }

  componentDidMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });

    SessionStore.on("change", this.setIsAdmin);

    this.setIsAdmin();
    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  deleteApplication() {
    if (window.confirm("Are you sure you want to delete this application? This will also delete all devices part of this application.")) {
      ApplicationStore.delete(this.props.match.params.applicationID, resp => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications`);
      });
    }
  }

  locationToTab() {
    let tab = 0;

    if (window.location.href.match(/.*\/integrations.*/g)) {
      tab = 2;
    } else if (window.location.href.endsWith("/edit")) {
      tab = 1;
    }

    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.application === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
      <TitleBar
        buttons={[
          <TitleBarButton
            key={1}
            icon={<EditIcon />}
            label="Button"
            to={`/`}
          />,
        ]}
      >
        <TitleBarTitle title={`Application Events (${this.state.application.application.name})`} />
      </TitleBar>
    </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(ApplicationEvents));
